import { Grid } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BodyOfTextProps {
    content: string
}

export const BodyOfText: React.FC<BodyOfTextProps> = props => {
    const { content } = getBodyOfTextData(props)
    return (
        <Grid container>
            <Grid item xs={12} md={8}>
                <Text html label={content} />
            </Grid>
        </Grid>
    )
}

export const getBodyOfTextData = (data: any): BodyOfTextProps => {
    return {
        content: data.body_of_text__body
    }
}
