import { Box } from '@chakra-ui/react'
import { Choice } from '@irishlifedigitalhub/ds.molecules.choice'
import React from 'react'
import { Controller } from 'react-hook-form'

export interface CalculatorStepMultipleChoiceProps {
    fieldName: string
    name?: string
    label?: string
    options?: any[]
    control?: any
}
export type ChoiceValue = string | number | boolean | Date | undefined

export const CalculatorStepMultipleChoice: React.FC<CalculatorStepMultipleChoiceProps> =
    ({ name, label, options, fieldName, control }) => {
        return (
            <Controller
                name={fieldName ?? name}
                control={control}
                defaultValue=''
                render={({ onChange }) => (
                    <Box display='flex'>
                        <Choice
                            key={fieldName}
                            defaultSelected={['option-1']}
                            isSecondary={true}
                            columns={[2, 2, 2, 2, 3]}
                            id={fieldName ?? name}
                            label={label}
                            isBinary={true}
                            onChange={(value: any) => {
                                if (value) {
                                    onChange(value)
                                } else {
                                    onChange('')
                                }
                            }}
                            options={options?.map(
                                (option: any, index: number) => {
                                    return {
                                        id: `option-${index}`,
                                        label: option?.option_label,
                                        value: option?.option_label,
                                    }
                                }
                            )}
                            size='md'
                            spacing={3}
                        />
                    </Box>
                )}
            />
        )
    }
