import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import React from 'react'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { usePensionCalculator } from '../utils/usePensionCalculator'
import { LegendDisplay } from './LegendDisplay'

export interface RetirementBarChartProps {}

export const RetirementBarChart: React.FC<RetirementBarChartProps> = () => {
    const { state } = useCalculatorMachine()
    const isRefetching = state.matches('Refetching Quote Details')

    const {
        state: { projection, statePension },
    } = usePensionCalculator()

    const total = projection + statePension
    const data = [
        { projection: projection / total, statePension: statePension / total },
    ]

    if (isRefetching) {
        return null
    }

    return (
        <>
            <ResponsiveContainer height={125} width={'100%'}>
                <BarChart
                    height={300}
                    width={412}
                    layout='vertical'
                    data={data}
                >
                    <defs>
                        <pattern
                            id='stripe'
                            width='4'
                            height='4'
                            patternUnits='userSpaceOnUse'
                            patternTransform='rotate(45)'
                        >
                            <rect
                                width='2'
                                height='4'
                                transform='translate(0,0)'
                                fill='#717FE2'
                            ></rect>
                        </pattern>
                    </defs>
                    <XAxis hide type='number' />
                    <YAxis type='category' dataKey='name' stroke='#333' hide />

                    <Bar
                        dataKey='projection'
                        fill='#717FE2'
                        style={{ transition: 'all' }}
                        stackId='a'
                        radius={
                            statePension ? [10, 0, 0, 10] : [10, 10, 10, 10]
                        }
                    ></Bar>
                    <Bar
                        dataKey='statePension'
                        fill='url(#stripe)'
                        stackId='a'
                        radius={[0, 10, 10, 0]}
                    ></Bar>
                </BarChart>
            </ResponsiveContainer>
            <LegendDisplay
                projection={projection}
                statePension={statePension}
            />
        </>
    )
}
