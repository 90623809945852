import { Box } from '@chakra-ui/react'
import {
    MenuCard,
    MenuCardProps,
} from '@irishlifedigitalhub/ds.molecules.menu-card'
import { Grid, GridProps } from '@irishlifedigitalhub/ds.organisms.grid'
import { UniversalLink } from 'common/components/atoms/UniversalLink'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MenuCardGridProps extends GridProps {}

export const MenuCardGrid: React.FC<MenuCardGridProps> = (props: any) => {
    return (
        <Box>
            <Grid {...getMenuCardGridProps(props)} />
        </Box>
    )
}

export const getMenuCardGridProps = (props: any): GridProps => {
    return {
        title: props.title,
        children: [<>{getMenuCards(props.menucard)}</>],
    }
}

export const getMenuCards = (menuCards: any[]): MenuCardProps[] => {
    return (menuCards || []).map((menuCard) => {
        return (
            <MenuCard
                key={menuCard.codename}
                {...getMenuCardsProps(menuCard)}
            />
        ) as MenuCardProps
    })
}

export const getMenuCardsProps = (menuCard: any): MenuCardProps => {
    return {
        buttonText: menuCard.menu_card__cta?.[0]?.call_to_action__label,
        icon: menuCard.menu_card__icon,
        largeTitle: menuCard.menu_card__maintitle,
        linkComponent: UniversalLink,
        src: menuCard.menu_card__image,
        subtitle: menuCard.menu_card__subtitle,
        title: menuCard.menu_card__title,
        url: menuCard.menu_card__cta?.[0]?.call_to_action__url,
    }
}
