import { Box } from '@chakra-ui/react'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { CampaignFeature, CampaignFeatureProps } from '../../CampaignFeature'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeaturedProps extends CampaignFeatureProps {}

export const Featured: React.FC<FeaturedProps> = props => {
    const desktop = useDesktop()
    return (
        <Box mt={desktop ? '-72px' : '-96px'} mb={desktop ? '-72px' : '-124px'}>
            <CampaignFeature {...getFeaturedData(props)} variant='flat' />
        </Box>
    )
}

const getFeaturedData = (data: any): FeaturedProps => {
    return {
        title: data.title,
        text: data.text,
        image: data.image,
        callToAction: {
            analyticsId: data.call_to_action__analytics_id,
            label: data.call_to_action__label,
            [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
            text: data.call_to_action__text,
            title: data.call_to_action__title,
            url: data.call_to_action__url,
            variant: data.call_to_action__variant
        }
    }
}
