import React, { FC } from 'react'
import { CenteredText } from 'common/components/organisms/CenteredText/index'

const CareerCenteredTextWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getCareersCenteredTextData(props)

    return <CenteredText {...processedProps} />
}
export { CareerCenteredTextWrapper as CenteredText }
export const getCareersCenteredTextData = (data: any) => {
    return {
        text: data.centered_text,
        background: data.untitled_multiple_choice || 'Light',
    }
}
