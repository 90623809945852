import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import { Grid, GridItem, Box } from '@chakra-ui/react'
import { MetamoCard, ItemProps } from 'common/components/molecules/MetamoCard'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { AdvisorBooking } from 'common/components/organisms/AdvisorBooking'
import { isDesktop } from 'react-device-detect'
import { PreScreen } from 'common/components/organisms/AdvisorBooking/utils/interface'
export interface Metamo extends RouteComponentProps {
    branchList: any
}

export const Metamo: React.FC<any> = (props) => {
    const branchList = getBranchList(props)
    const isAdvisorView = props.isadvisorview === 'yes' ? true : false
    const [selectedBranch, setSelectedBranch] = useState<ItemProps>(
        branchList[0]
    )
    const desktop = isDesktop
    const [showAdvisorbooking, setShowAdvisorbooking] = useState<boolean>(false)
    const handleClick = (value: ItemProps) => {
        setSelectedBranch(value)
        setShowAdvisorbooking(true)
    }
    return (
        <Box
            bg={'brand.200'}
            px={'72px'}
            w={'100%'}
            py={'60px'}
            borderRadius={'16px'}
        >
            <Box pb={'48px'}>
                <Text variant={'title-xl'} color={'monochrome.900'}>
                    Choose your branch
                </Text>
                <Grid
                    pt={'36px'}
                    rowGap={'16px'}
                    templateColumns={
                        desktop ? 'repeat(4, 1fr)' : 'repeat(1, 1fr)'
                    }
                >
                    {branchList.map((item: ItemProps) => (
                        <GridItem key={item.branchId}>
                            <MetamoCard item={item} onClick={handleClick} />
                        </GridItem>
                    ))}
                </Grid>
            </Box>
            {showAdvisorbooking && (
                <Box>
                    <Text
                        variant={'title-xl'}
                        color={'monochrome.900'}
                        pb={'36px'}
                    >
                        Select an option that suits you
                    </Text>
                    <AdvisorBooking
                        preScreen={getPreScreen(isAdvisorView, selectedBranch)}
                        slim
                    />
                </Box>
            )}
        </Box>
    )
}

const getPreScreen = (
    isAdvisorView: boolean,
    selectedBranch: ItemProps
): PreScreen => {
    return {
        metamoVariant: true,
        isAdvisorView: isAdvisorView,
        sourceBranchName: selectedBranch.branchName.replace(/ /g, ''),
    }
}
const getBranchList = (data: any): ItemProps[] => {
    const branchList = data?.metamo_branch_list?.map((item: any) => ({
        branchName: item.branch_name,
        branchId: item.branch_id,
        icon: item.icon,
    }))
    return branchList
}
