import React from 'react'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { GetStartedProps } from '../../common/GetStarted'
import { AdjustmentFormProps } from '../../common/AdjustmentForm'
import {
    TermLifeFormType,
    CalculatorMachineEvent,
} from 'common/components/organisms/Calculator/utils/interfaces'
import {
    CalculatorQuoteDisplay,
    CalculatorQuoteDisplayProps,
} from '../../common/CalculatorQuoteDisplay'
export interface TermLifeCalculatorOutputProps {}

export const TermLifeCalculatorOutput: React.FC<TermLifeCalculatorOutputProps> =
    () => {
        const { state, send } = useCalculatorMachine()
        const formValues = state.context.formValues as TermLifeFormType
        const getStarted: GetStartedProps = {
            title: 'Get Started',
            subtitle:
                'Our expert financial advisors are on hand to help you get started.',
            phoneNumber: '(01) 704 1979',
            bodyText: `This indicative quote from Irish Life Financial Services is
                    for a Term Life Insurance policy from Irish Life Assurance
                    plc and is subject to further assessment of health status.
                    This assessment may result in an increase in the cost of
                    cover. Irish Life Assurance plc, trading as Irish Life, is
                    regulated by the Central Bank of Ireland.`,
        }
        const onSubmit = (data: any) => {
            if (!data.coverNeeded && !data.term) {
                return
            }
            let user = {}
            if (state.context.formValues) {
                user = {
                    ...state?.context?.formValues,
                    coverNeeded: data.coverNeeded || formValues.coverNeeded,
                    maxAge: +data.term
                        ? +formValues.age + +data.term
                        : formValues.maxAge,
                }
            }
            send({
                type: 'Get Adjusted Quote',
                payload: { user },
            } as CalculatorMachineEvent)
        }
        const adjustmentFormProps: AdjustmentFormProps = {
            onSubmit,
            coverNeeded: formValues.coverNeeded,
            term: (+formValues.maxAge - +formValues.age).toString(),
            peopleCovered: 'Single person',
        }
        const quoteDisplayProps: CalculatorQuoteDisplayProps = {
            getStarted,
            adjustmentFormProps,
        }
        return <CalculatorQuoteDisplay {...quoteDisplayProps} />
    }
