import { Box } from '@chakra-ui/react'
import {
    ProductList,
    ProductListProps,
} from 'common/components/organisms/ProductList'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardGridProps extends ProductListProps {}

const fullWidthWrapperStyles = {
    backgroundColor: '#EBEDF7',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    display: 'flex',
    justifyContent: 'center',
}

export const CardGrid: React.FC<CardGridProps> = (props) => {
    const data = getCardGridData(props)
    return (
        <Box>
            <Box sx={fullWidthWrapperStyles}>
                <ProductList {...data} transparent={true} generic={true} />
            </Box>
        </Box>
    )
}

export const getCardGridData = (data: any): ProductListProps => {
    return {
        heading: data.cardgrid__heading,
        products: (data.cardgrid__products || []).map((product: any) => ({
            icon: product.icon,
            subtitle: product.subtitle,
            title: product.title,
            text: product.text,
            callToAction: {
                label: product.card_cta_0396ba7__cta_label,
                variant: product.card_cta_0396ba7__cta_variant,
                url: product.card_cta_0396ba7__cta_url,
            },
        })),
        text: data.cardgrid__text,
        title: data.cardgrid__title,
    }
}
