import { Box } from '@material-ui/core'
import { AddBox } from '@material-ui/icons'
import { Text } from 'common/components/molecules/Text'
import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BulletProps {
    icon?: string
    text: string
    title: string
}

export const Bullet: React.FC<BulletProps> = ({ title }) => {
    return (
        <Box display='flex' alignItems='center'>
            <AddBox
                fontSize='large'
                style={{ color: '#50C9B5', marginRight: 8 }}
            />
            <Text
                color='textSecondary'
                label={title}
                style={{ whiteSpace: 'nowrap' }}
                variant='subtitle1'
            />
        </Box>
    )
}
