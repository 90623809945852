import React, { useState } from 'react'
import { Box, Text, SimpleGrid, VStack } from '@chakra-ui/react'
import Card from './Card'
import { Pagination } from '../../molecules/Pagination/index'

interface CountingCardsWithPaginationProps {
    title: string
    items: { title: string; text: string }[]
}

export const CountingCardsWithPagination: React.FC<CountingCardsWithPaginationProps> =
    ({ title, items }) => {
        const [visibleItems, setVisibleItems] = useState([])
        const [currentPage, setCurrentPage] = useState(1)
        const itemsPerPage = 3

        const handlePagination = (paginationData: any) => {
            setVisibleItems(paginationData.visibleItems)
            setCurrentPage(paginationData.currentPage)
        }

        const calculateCounter = (index: number) => {
            return (currentPage - 1) * itemsPerPage + index + 1
        }

        return (
            <VStack spacing='64px' justify='center' align='center' w='full'>
                <Text
                    fontSize='40px'
                    fontStyle='normal'
                    fontWeight='500'
                    color='#3A3160'
                    textAlign='center'
                    w={['auto', 'auto', '65%']}
                >
                    {title}
                </Text>

                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} w='full'>
                    {visibleItems.map((item: any, index) => (
                        <Card
                            key={index}
                            title={item.title}
                            text={item.text}
                            counter={calculateCounter(index)}
                        />
                    ))}
                </SimpleGrid>

                <Box w='full'>
                    <Pagination
                        items={items}
                        callBack={handlePagination}
                        itemsPerPage={itemsPerPage}
                    />
                </Box>
            </VStack>
        )
    }
