import FormControl from '@material-ui/core/FormControl'
import INPUT_BASE from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles,
} from '@material-ui/core/styles'
import EXPAND_MORE from '@material-ui/icons/ExpandMore'
import React, { useEffect, useState } from 'react'
import { theme } from '../../theme'

export interface SelectProps {
    value: string | number
    isDisabled?: boolean
    options: {
        value: string | number
        label: string
    }[]
    isOptional?: boolean
    isWhiteBackground?: boolean
    onChange?: () => void
    showOptional: boolean
}

const { palette } = theme

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '320px',
            height: '48px',
            '&:before': {
                borderBottom: '0px',
            },
            marginTop: '4px',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        empty: {
            color: `${palette.NEUTRAL_300} !important`,
        },
        dropdownStyle: {
            border: '0px',
            borderRadius: '0px 0px 4px 4px',
            backgroundColor: palette.WHITE,
            marginTop: '52px',
            '& ul': {
                padding: '0px',
            },
        },
        menuItem: {
            fontWeight: 700,
            height: '48px',
            '&:hover': {
                backgroundColor: palette.VIBRANT_100_SECONDARY,
            },
            '&:active': {
                backgroundColor: palette.VIBRANT_100_SECONDARY,
            },
        },
        icon: {
            fill: palette.NEUTRAL_900,
            right: '14px',
        },
        iconDisabled: {
            fill: palette.NEUTRAL_300,
            right: '14px',
        },
        optional: {
            fontStyle: 'italic',
        },
    })
)

const inputStyles = {
    width: '320px',
    height: '20px',
    borderRadius: '4px',
    backgroundColor: palette.BRAND_100,
    border: '2px solid transparent',
    fontSize: 16,
    padding: '14px',
    fontWeight: 400,
    color: palette.NEUTRAL_900,
    fontFamily: 'Assistant',
    '&:hover': {
        borderColor: palette.BRAND_200,
    },
    '&:active': {
        borderRadius: 4,
        borderColor: '#3681D9',
    },
    '&:focus': {
        backgroundColor: palette.BRAND_100,
    },
}

const DefaultInput = withStyles(() =>
    createStyles({
        input: {
            ...inputStyles,
        },
    })
)(INPUT_BASE)

const DefaultInputWhite = withStyles(() =>
    createStyles({
        input: {
            ...inputStyles,
            backgroundColor: palette.WHITE,
            '&:focus': {
                backgroundColor: palette.WHITE,
            },
        },
    })
)(INPUT_BASE)

const PlaceholderInput = withStyles(() =>
    createStyles({
        input: {
            ...inputStyles,
            color: palette.NEUTRAL_300,
        },
    })
)(INPUT_BASE)

const PlaceholderInputWhite = withStyles(() =>
    createStyles({
        input: {
            ...inputStyles,
            backgroundColor: palette.WHITE,
            color: palette.NEUTRAL_300,
            '&:focus': {
                backgroundColor: palette.WHITE,
            },
        },
    })
)(INPUT_BASE)

export const SimpleSelect = ({ ...props }) => {
    const {
        value,
        options,
        isDisabled,
        isOptional,
        isWhiteBackground,
        onChange,
        showOptional,
    } = props
    const classes = useStyles()
    const [selectedValue, setValue] = useState<string>()

    useEffect(() => {
        setValue(value === undefined ? '' : value)
    }, [value])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string)
        onChange(event.target.value)
    }
    
    return (
        <FormControl
            className={`${classes.formControl} ${
                selectedValue === '' && classes.empty
            }`}
        >
            <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                displayEmpty={selectedValue === ''}
                disabled={isDisabled}
                value={selectedValue}
                onChange={handleChange}
                disableUnderline
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                input={
                    isWhiteBackground ? (
                        selectedValue === '' ? (
                            <PlaceholderInputWhite />
                        ) : (
                            <DefaultInputWhite />
                        )
                    ) : selectedValue === '' ? (
                        <PlaceholderInput />
                    ) : (
                        <DefaultInput />
                    )
                }
                IconComponent={EXPAND_MORE}
                inputProps={
                    isDisabled
                        ? { classes: { icon: classes.iconDisabled } }
                        : { classes: { icon: classes.icon } }
                }
            >
                <MenuItem className={classes.menuItem} value='' disabled>
                    Select an option{' '}
                    {isOptional && showOptional && (
                        <span className={classes.optional}>- optional</span>
                    )}
                </MenuItem>
                {options &&
                    options.length > 0 &&
                    options.map(({ value, label }: any) => (
                        <MenuItem
                            key={value}
                            value={value}
                            className={classes.menuItem}
                        >
                            {label}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}
