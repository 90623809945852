import React, { FC } from 'react'
import { ResourcesWithLinks } from 'common/components/organisms/ResourcesWithLinks/index'

const ResourcesWithLinksWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <ResourcesWithLinks {...processedProps} />
}
export { ResourcesWithLinksWrapper as ResourcesWithLinks }
const getProps = (props: any) => {
    return {
        title: props.title,
        subtitle: props.subtitle,
        description: props.description,
        poplink: props.poplink.map((item: any) => ({
            icon: item.icon,
            title: item.title,
            url: item.url,
        })),
    }
}
