import { Box, Grid, Hidden } from '@material-ui/core'
import { CardCtaContained } from 'common/components/molecules/CardCta'
import { Text } from 'common/components/molecules/Text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { useDesktop } from 'common/hooks/useDesktop'
import { useDpr } from 'common/hooks/useDpr'
import React from 'react'
import { JumbotronCallToActionProps } from '../Jumbotron/JumbotronCallToAction'

export interface CampaignFeatureProps {
    callToAction: JumbotronCallToActionProps
    image: string
    text: string
    title: string
    variant?: 'default' | 'flat'
}

export const CampaignFeature: React.FC<CampaignFeatureProps> = ({
    title,
    text,
    image,
    callToAction,
    variant = 'default'
}) => {
    const desktop = useDesktop()
    const dpr = useDpr()

    return (
        <Box
            data-testid='CampaignFeature'
            py={desktop ? 18 : 24}
            display='flex'
            flexDirection='column'
            style={{
                background:
                    variant === 'flat'
                        ? undefined
                        : 'radial-gradient(71.23% 43.94% at 10.65% 72.43%, rgba(247, 247, 249, 0.8) 0%, rgba(247, 247, 249, 0) 100%), #EBEDF7'
            }}
            component='section'
        >
            <Wrapper>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <img
                            alt='App'
                            src={`${image}?format=auto&w=400&dpr=${dpr}`}
                            width='100%'
                        />
                    </Grid>
                    <Hidden>
                        <Grid item xs={12} md={1} />
                    </Hidden>
                    <Grid item xs={12} md={5}>
                        <Text
                            box={{
                                mb: 7,
                                mt: desktop ? 0 : 12,
                                textAlign: desktop ? undefined : 'center'
                            }}
                            label={title}
                            variant='h3'
                            component='h1'
                        />
                        <Text
                            box={{
                                color: '#766F90',
                                mb: 7,
                                textAlign: desktop ? undefined : 'center'
                            }}
                            html
                            label={text}
                            component='p'
                        />
                        <Box
                            display='flex'
                            justifyContent={desktop ? undefined : 'center'}
                        >
                            {callToAction.label && (
                                <CardCtaContained {...callToAction} />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Wrapper>
        </Box>
    )
}
