import { Box, HStack, SimpleGrid } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Input } from '@irishlife/ilgroupdesignsystem.molecules.input'
import { Link } from 'common/components/atoms/Link'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

interface MiniFooterProps {
    loginLink?: string
}

export const MiniFooter: React.FC<MiniFooterProps> = (props) => {
    const { loginLink = '/' } = props

    const isMobile = !useDesktop()

    return (
        <Box bgColor='#4D599E' p='32px 0'>
            <SimpleGrid columns={{ sm: 1, md: 2 }}>
                <Text
                    variant='title-sm'
                    fontWeight='medium'
                    color='#FFF'
                    w='80%'
                    pb={isMobile ? '32px' : ''}
                    mt={isMobile ? '' : '25px'}
                >
                    Keep in touch and stay informed about new job opportunities
                    that match your interests.
                </Text>
                <Box
                    sx={
                        isMobile
                            ? {
                                  pt: '32px !important',
                                  borderTop: '2px solid #FFFFFF',
                              }
                            : {}
                    }
                >
                    <Text fontWeight='semibold' color='#FFF' pb={2}>
                        Create your profile
                    </Text>

                    <Input placeholder='Enter your email address' />
                    <Link path={loginLink}>
                        <HStack justifyContent='flex-end' pt={2}>
                            <Text fontWeight='semibold' color='#D8D6DF'>
                                Already registered? Log in
                            </Text>
                        </HStack>
                    </Link>
                </Box>
            </SimpleGrid>
        </Box>
    )
}
