import { Box, SimpleGrid, VStack } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React, { useState } from 'react'

import { useDesktop } from 'common/hooks/useDesktop'
import { Pagination } from '../../molecules/Pagination/index'
import { FeatureList, FeatureListProps } from '../FeatureList'

interface MultiFeatureListProps {
    title: string
    caption: string
    items: FeatureListProps[]
}

export const MultiFeatureList: React.FC<MultiFeatureListProps> = ({
    title,
    caption,
    items,
}) => {
    const [selectedCardIndex, setSelectedCardIndex] = useState(0)
    const [visibleItems, setVisibleItems] = useState<{ title: string }[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const isMobile = !useDesktop()

    const cardStyle = (index: number) => ({
        borderBottom:
            index === selectedCardIndex
                ? ' 6px solid #039FBF'
                : '2px solid transparent',
    })

    const handlePagination = (paginationItems: any) => {
        setVisibleItems(paginationItems.visibleItems)
        setCurrentPage(paginationItems.currentPage)
    }

    return (
        <Box>
            <VStack spacing='32px' justify='center' align='center'>
                <Box w={['auto', 'auto', '80%']}>
                    <Box mb={8}>
                        <Text
                            variant='title-xl'
                            color='#3A3160'
                            style={{ textAlign: 'center' }}
                        >
                            {title}
                        </Text>
                    </Box>
                    <Box pb={16}>
                        <Text
                            variant='title-xs'
                            color='#766F90'
                            style={{ textAlign: 'center' }}
                        >
                            {caption}
                        </Text>
                    </Box>
                </Box>
            </VStack>

            <SimpleGrid gap={6} columns={{ base: 1, md: 4 }}>
                {visibleItems?.map((item, index) => (
                    <Card
                        style={{
                            ...cardStyle(
                                (currentPage - 1) * (isMobile ? 1 : 4) + index
                            ),
                            padding: 0,
                        }}
                        onClick={() =>
                            setSelectedCardIndex(
                                (currentPage - 1) * (isMobile ? 1 : 4) + index
                            )
                        }
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        cursor={'pointer'}
                    >
                        <Box as='section' py={6} px='6px'>
                            <Text
                                variant='title-sm'
                                color='#4D599E'
                                style={{ textAlign: 'center' }}
                            >
                                {item.title}
                            </Text>
                        </Box>
                    </Card>
                ))}
            </SimpleGrid>

            <Box pt='64px' display={items.length < 5 ? 'none' : ''}>
                <Pagination
                    items={items}
                    itemsPerPage={isMobile ? 1 : 4}
                    callBack={handlePagination}
                />
            </Box>

            <Box pt='64px'>
                <FeatureList {...items[selectedCardIndex]} />
            </Box>
        </Box>
    )
}
