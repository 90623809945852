import React from 'react'
import { Video, VideoProps } from 'common/components/atoms/Video'



// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VideoWithTextProps extends VideoProps {}

export const VideoWithText: React.FC<VideoWithTextProps> = props => {
    const data = getVideoWithTextData(props)
    return (
        <Video {...data} />
    )
}

export const getVideoWithTextData = (data: any): VideoWithTextProps => {
    return {
        height: data.video__height,
        width: data.video__width,
        url: data.video__url,
        source: data.video__video,
        title: data.video__title,
        heading: data.video__heading,
        text: data.video__text
    }
}

