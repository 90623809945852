import { Box } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import React from 'react'

export interface NextStepsCardProps {}

export const NextStepsCard: React.FC<NextStepsCardProps> = () => {
    const { send } = useCalculatorMachine()
    const restartQuote = () => {
        send({
            type: 'Calculate Again',
        })
    }
    return (
        <>
            <Box pt={'16px'}>
                <Card
                    backgroundColor={'var(--Brand-700, #4D599E)'}
                    py={['36px', '36px']}
                    px={['24px', '24px']}
                >
                    <Text
                        color={'var(--White, #FFF)'}
                        pb={'20px'}
                        variant={'title-sm'}
                    >
                        Next Steps
                    </Text>
                    <Text
                        color={'var(--White, #FFF)'}
                        pb={'16px'}
                        variant={'body-md'}
                    >
                        Speak to a Irish Life Financial Services advisor about
                        your pension options.
                    </Text>
                    <a href='tel:+353(01)7041104'>
                        <Button variant='outline' size='md'>
                            <img
                                alt='phone icon'
                                style={{ paddingRight: '8px' }}
                                color={'#5E6CDA'}
                                src='https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/542072f4-556c-4e37-90f8-8dd2b19e0aee/Phone-brand-28px.svg'
                            />
                            Call us (01) 704 1104
                        </Button>
                    </a>
                </Card>
            </Box>
            <Button
                onClick={restartQuote}
                variant='outline'
                size='lg'
                mt={'16px'}
            >
                Restart Calculator
            </Button>
        </>
    )
}
