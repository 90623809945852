import { Box } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import React from 'react'
import { PaginatedCards } from '../../molecules/PaginatedCards/index'

interface Card {
    icon: string
    description: string
}

interface ItemsProps {
    cardTitle?: string
    cards: Card[]
}

interface MultiPaginatedCardsProps {
    title: string
    subTitle: string
    items: ItemsProps[]
    secondSubtitle?: string
    secondTitle?: string
}

export const MultiPaginatedCards: React.FC<MultiPaginatedCardsProps> = (
    props
) => {
    const { title, subTitle, items, secondSubtitle, secondTitle } = props

    return (
        <Box>
            <Text
                variant={{ sm: 'title-lg', md: 'title-xl' }}
                fontWeight={'medium'}
                w={'80%'}
                color={'#3A3160'}
                m={'0 auto'}
                textAlign={'center'}
            >
                {title}
            </Text>
            <Text
                variant={'title-xs'}
                color={'#766F90'}
                fontWeight={'semibold'}
                w={'80%'}
                py={8}
                m={'0 auto'}
                textAlign={'center'}
            >
                {subTitle}
            </Text>
            <Box backgroundColor={'#C1C7E1'} h={'2px'} />
            {items?.map((item: ItemsProps, index) => (
                <Box my={8} key={index}>
                    <Text
                        variant={'body-lg'}
                        color={'#5261AC'}
                        py={8}
                        fontWeight={'semibold'}
                    >
                        {item.cardTitle}
                    </Text>
                    <Box>
                        <PaginatedCards cards={item.cards || []} />
                    </Box>
                </Box>
            ))}
            <Box backgroundColor={'#C1C7E1'} h={'2px'} />

            <Text
                variant={'title-xs'}
                fontWeight={'semibold'}
                color={'#766F90'}
                w={'80%'}
                py={8}
                m={'0 auto'}
                textAlign={'center'}
            >
                {secondSubtitle}
            </Text>
            <Text
                variant={{ sm: 'title-lg', md: 'title-xl' }}
                fontWeight={'medium'}
                w={'80%'}
                color={{ sm: '#4E4670', md: '#3A3160' }}
                pt={{ sm: 8, md: 35 }}
                m={'0 auto'}
                textAlign={'center'}
            >
                {secondTitle}
            </Text>
        </Box>
    )
}
