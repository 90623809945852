import { Flex } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import numeral from 'numeral'
import React from 'react'
import { usePensionCalculator } from '../utils/usePensionCalculator'

export interface MonthlyIncomeDisplayProps {}

export const MonthlyIncomeDisplay: React.FC<MonthlyIncomeDisplayProps> = () => {
    const {
        state: { projection, retirementAge, statePension },
    } = usePensionCalculator()

    return (
        <Flex
            paddingBottom={'12px'}
            align='start'
            justify='flex-start'
            gap={'6px'}
            wrap='nowrap'
        >
            <Text color={'#1D2F7C'} variant={'title-xl'}>
                €{numeral(projection + statePension).format('0,000')}
            </Text>
            <Text paddingTop={'12px'} variant={'body-md'}>
                {`from your ${retirementAge}th birthday`}
            </Text>
        </Flex>
    )
}
