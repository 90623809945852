import React from 'react'
import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import { SimpleSelect } from '../../atoms/select'
import { Label } from '../../atoms/label'
import { theme } from '../../theme'

export interface SelectProps {
    value: string | number
    isDisabled?: boolean
    options: {
        value: string | number
        label: string
    }[]
    isOptional?: boolean
    isDarkBackground?: boolean
    onChange?: () => void
    label?: string
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        width: '328px'
    },
    darkBackground: {
        backgroundColor: 'rgb(245, 244, 247)'
    },
    optional: {
        fontSize: '14px',
        letterSpacing: '0.32px',
        lineHeight: '20px',
        fontFamily: 'Assistant',
        color: palette.NEUTRAL_700,
        fontStyle: 'italic',
        fontWeight: 400,
        paddingBottom: '4px'
    }
}

export const Select = ({ ...props }) => {
    const {
        value,
        options,
        isDisabled,
        isOptional,
        isDarkBackground,
        onChange,
        label,
        classes
    } = props

    return (
        <div
            className={`${classes.root} ${isDarkBackground &&
                classes.darkBackground}`}
        >
            <div>
                {label && <Label value={label} isDisabled={isDisabled} />}
                {isOptional && label !== undefined && label !== '' && (
                    <span className={classes.optional}> - Optional</span>
                )}
            </div>
            <SimpleSelect
                options={options}
                isOptional={isOptional}
                showOptional={label === undefined && isOptional}
                isWhiteBackground={isDarkBackground}
                isDisabled={isDisabled}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}
export const Dropdown = withStyles(styles)(Select)
