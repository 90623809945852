import {
    LatestNews,
    LatestNewsProps
} from 'common/components/organisms/LatestNews'
import { getArticleData } from 'common/utils/getArticleData'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardsCarouselProps extends LatestNewsProps {}

export const CardsCarousel: React.FC<CardsCarouselProps> = props => {
    const context = getCardsCarouselData(props)
    return <LatestNews {...context} />
}

const getCardsCarouselData = (data: any): CardsCarouselProps => {
    return {
        label: data.cardscarousel__label,
        list: data.blogpostselector__blog_posts.map(getArticleData)
    }
}
