import {
    ProductRowLists,
    ProductRowListsProps,
} from 'common/components/organisms/ProductRowLists'
import React from 'react'

export interface ProductListingProps extends ProductRowListsProps {}
export const ProductListing: React.FC<ProductListingProps> = (props) => {
    return <ProductRowLists {...getProductRowListingProps(props)} />
}

const getProductRowListingProps = (data: any): ProductListingProps => {
    return {
        title: data.title,
        subTitle: data.subtitle,
        text: data.text,
        productRowLists: data.product_lists.map((card: any) => ({
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url,
            },
            icon: card.icon,
            text: card.text,
            title: card.title,
            subtitle: card.subtitle,
            ishighlighted: card.ishighlighted === 'highlighted' ? true : false,
        })),
    }
}
