import { Box, HStack, Image, SimpleGrid } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { SearchIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { Input } from '@irishlife/ilgroupdesignsystem.molecules.input'
import { Link } from 'common/components/atoms/Link'
import { Dropdown } from 'common/components/design-system/molecules/selectDropdown'
import React, { useEffect, useState } from 'react'
import { Pagination } from '../../molecules/Pagination/index'
interface CardProps {
    image?: string
    name: string
    jobTitle: string
    company: string
    department: string
}
interface TeamGridFilterProps {
    title: string
    cards: CardProps[]
    showFilters: string
    callToAction?: {
        buttonLabel: string
        buttonUrl: string
    }
}

export const TeamGridFilter: React.FC<TeamGridFilterProps> = ({
    title,
    cards,
    showFilters,
    callToAction,
}) => {
    const [visibleItems, setVisibleItems] = useState<CardProps[]>()
    const [cardStore, setCardStore] = useState<CardProps[]>(cards)
    const [filterValues, setFilterValues] = useState<any>({})
    const [companyFilterOptions, setCompanyFilterOptions] = useState<any>([])

    useEffect(() => {
        let idIterator = 0
        const unique = Array.from(new Set(cards.map((card) => card.company)))

        setCompanyFilterOptions(
            unique.map((companyName) => ({
                id: idIterator++,
                label: companyName,
                value: companyName,
            }))
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePagination = (array: any) => {
        setVisibleItems(array.visibleItems)
    }

    const clearAllFilters = () => {
        setFilterValues({})
        setCardStore(cards)
    }

    const applyFilters = (value: string, inputName: string) => {
        if (inputName === 'company') {
            const filtered = cards.filter((card) =>
                card.company?.toLowerCase().startsWith(value.toLowerCase())
            )
            if (filterValues?.department) {
                setCardStore(
                    filtered.filter(
                        (card) =>
                            card.department
                                ?.toLowerCase()
                                .startsWith(
                                    filterValues.department.toLowerCase()
                                ) ||
                            card.department?.toLowerCase() ===
                                filterValues.department.toLowerCase()
                    )
                )
            } else {
                setCardStore(filtered)
            }
            setFilterValues({ ...filterValues, [inputName]: value })
        }

        if (inputName === 'department') {
            if (value !== '') {
                const filtered = cards.filter(
                    (card) =>
                        card.department
                            ?.toLowerCase()
                            .startsWith(value.toLowerCase()) ||
                        card.department?.toLowerCase() === value.toLowerCase()
                )

                if (filterValues?.company) {
                    setCardStore(
                        filtered.filter((card) =>
                            card.company
                                ?.toLowerCase()
                                .startsWith(filterValues.company.toLowerCase())
                        )
                    )
                } else {
                    setCardStore(filtered)
                }

                setFilterValues({ ...filterValues, [inputName]: value })
            } else {
                if (filterValues?.company) {
                    const filtered = cards.filter((card) =>
                        card.company
                            ?.toLowerCase()
                            .startsWith(filterValues.company.toLowerCase())
                    )
                    setCardStore(filtered)
                } else setCardStore(cards)
                setFilterValues((prevValues: any) => {
                    delete prevValues.department
                    return prevValues
                })
            }
        }
    }

    return (
        <Box>
            <Text color='#3A3160' variant='title-xl' mb={16}>
                {title}
            </Text>
            {showFilters === 'True' && (
                <>
                    <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={8} mb={8}>
                        <Box>
                            <Input
                                label='Search departments'
                                placeholder='Start typing…'
                                onChange={(event) =>
                                    applyFilters(
                                        event.target.value,
                                        'department'
                                    )
                                }
                                value={filterValues?.department || ''}
                            />

                            <Link path={''} onClick={() => {}}>
                                <Text
                                    mt={4}
                                    color={'#5E6CDA'}
                                    fontWeight='semibold'
                                    cursor={'pointer'}
                                    w={'fit-content'}
                                >
                                    View all departments
                                </Text>
                            </Link>
                        </Box>

                        <Dropdown
                            style={{ width: '100%' }}
                            id='sample-dropdown'
                            label='Company'
                            onBlur={() => {}}
                            onChange={(value: string) =>
                                applyFilters(value, 'company')
                            }
                            options={companyFilterOptions}
                            placeholder='Please select an option'
                            value={filterValues?.company || undefined}
                        />
                        <Box>
                            <HStack
                                justifyContent={'start'}
                                alignItems={'center'}
                            >
                                <HStack alignItems='center' p={8}>
                                    <Box>
                                        <SearchIcon
                                            color='#5E6CDA'
                                            fontSize={24}
                                        />
                                    </Box>
                                    <Text
                                        fontWeight='normal'
                                        color='#5E6CDA'
                                        px={2}
                                    >
                                        Filters
                                    </Text>

                                    <HStack
                                        w={4}
                                        h={4}
                                        p={4}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        borderRadius='50%'
                                        bgColor='#F1F3FD'
                                        color='#5261AC'
                                    >
                                        <Box>
                                            {Object.keys(filterValues).length}
                                        </Box>
                                    </HStack>
                                </HStack>
                                <Text
                                    cursor={'pointer'}
                                    borderLeft={'1px solid #5E6CDA'}
                                    p={8}
                                    color={'#5E6CDA'}
                                    fontWeight='semibold'
                                    onClick={clearAllFilters}
                                >
                                    Clear all
                                </Text>
                            </HStack>
                        </Box>
                    </SimpleGrid>
                    <Box bgColor='#C1C7E1' w='100%' h='2px' mb={8}></Box>
                </>
            )}
            <SimpleGrid columns={{ md: 1, lg: 3 }} spacing={8}>
                {visibleItems?.map((card, index) => (
                    <HStack
                        p='16px 24px'
                        alignItems='center'
                        bgColor='#F7F8FB'
                        borderRadius='12px'
                        boxShadow='5px 10px 20px 0px rgba(43, 46, 140, 0.10)'
                        key={index + card.name}
                    >
                        <Image
                            alt='Simple Image'
                            w='120px'
                            h='120px'
                            borderRadius='50%'
                            mr='16px'
                            src={card.image}
                            srcSet={`
                                ${card.image}?auto=format&w=120 120w,
                                ${card.image}?auto=format&w=240 240w,
                                ${card.image}?auto=format&w=360 360w
                            `}
                            sizes='120px'
                        />
                        <Box>
                            <Text
                                pb={4}
                                color={'#0C1E68'}
                                fontWeight='semibold'
                                variant={'title-xs'}
                            >
                                {card.name}
                            </Text>
                            <Text color={'#0C1E68'} fontWeight='medium'>
                                {card.jobTitle}, {card.department}
                            </Text>
                        </Box>
                    </HStack>
                ))}
            </SimpleGrid>

            <SimpleGrid mt={16}>
                <Pagination
                    items={cardStore}
                    itemsPerPage={6}
                    callBack={handlePagination}
                />
            </SimpleGrid>
        </Box>
    )
}
