import { Box } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React from 'react'

export interface ValidityCardProps {
    title: string
    description: string
}

export const ValidityCard: React.FC<ValidityCardProps> = (validity) => {
    return (
        <Box pt={'12px'} mb={'16px'}>
            <Card px={'36px'}>
                <Text variant={'title-xs'}>{validity?.title}</Text>
                <Box mt={'16px'}>
                    <Text variant={'body-md'}>{validity?.description}</Text>
                </Box>
            </Card>
        </Box>
    )
}
