import React from 'react'
import { ValidityCard } from '../common/ValidityCard'
import { useCalculatorMachine } from '../../../utils/context'
import { BenefitsCard } from '../common/BenefitsCard'
import { Box } from '@chakra-ui/react'
import { GetStarted } from './../common/GetStarted'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { CoverRequirement } from './OnePlanProtectionOutput/components/CoverRequirements'
import { OnePlanProtectionOutput } from './OnePlanProtectionOutput'
import { isEmpty } from 'lodash'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { ArrowRightCircleIcon } from '@irishlife/ilgroupdesignsystem.icons'
import {
    getStarted,
    benefits,
    validity,
    benefitsTitle,
    RiskCard,
} from './OnePlanProtectionOutput/utils/helperFunctions'
export interface OnePlanProtectionQuoteProps {
    props?: any
}
export const OnePlanProtectionQuote: React.FC<OnePlanProtectionQuoteProps> =
    () => {
        const { state, send } = useCalculatorMachine()
        const restartQuote = () => {
            send({
                type: 'Calculate Again',
            })
        }
        if (
            state.context.quoteData?.error ||
            isEmpty(state.context.quoteData?.comprehensiveQuoteData) ||
            isEmpty(state.context.quoteData?.essentialQuoteData) ||
            isEmpty(state.context.quoteData?.standardQuoteData)
        ) {
            send({ type: 'ERROR OCCURED' })
        }
        return (
            <Box>
                <OnePlanProtectionOutput />
                <Card px={'36px'}>
                    <GetStarted {...getStarted} />
                </Card>
                <ValidityCard {...validity} />
                <ValidityCard {...RiskCard} />
                <BenefitsCard benefits={benefits} title={benefitsTitle} />
                <CoverRequirement />
                <Button
                    onClick={restartQuote}
                    variant='primary'
                    size='lg'
                    rightIcon={<ArrowRightCircleIcon />}
                    mt={'16px'}
                >
                    Restart Quote
                </Button>
            </Box>
        )
    }
