import { Box } from '@chakra-ui/react'
import { BasicButton } from 'common/components/atoms/BasicButton'
import { Text } from 'common/components/molecules/Text'
import { CtaProps } from 'common/components/organisms/templateSections/Cta'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface BannerProps {
    title: string
    subtitle?: string
    text?: string
    callToAction: CtaProps
}

const breakoutStyles = {
    backgroundColor: '#5261ac',
    marginRight: '-50vw',
    width: '100vw',
    marginLeft: '-50vw',
    left: '50%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    right: '50%',
    alignItems: 'center',
    zIndex: '2',
}

export const Banner: React.FC<BannerProps> = ({
    title,
    subtitle,
    text,
    callToAction,
}) => {
    const desktop = useDesktop()
    const phoneNumberRegex = /^tel:/
    const isPhoneNumber = callToAction.url
        ? phoneNumberRegex.test(callToAction.url)
        : false

    return (
        <Box data-testid='Banner' py={10} sx={breakoutStyles}>
            <Text
                label={title}
                variant={desktop ? 'h4' : 'h2'}
                style={{
                    color: 'white',
                    fontSize: '1.8rem',
                    textAlign: 'center',
                }}
            />
            {subtitle && (
                <Text
                    label={subtitle}
                    variant='subtitle1'
                    style={{ color: 'white', marginTop: '14px' }}
                />
            )}
            {text && (
                <Text
                    label={text}
                    variant='caption'
                    style={{ color: 'white', marginTop: '14px' }}
                />
            )}
            {isPhoneNumber ? (
                <a href={callToAction.url}>
                    <BasicButton
                        variant='outlined'
                        label={callToAction.label}
                        analyticsId={callToAction.label}
                        analyticsEventName='secondaryButtonClick'
                    />
                </a>
            ) : (
                // Otherwise, render the regular button
                <BasicButton
                    variant='outlined'
                    label={callToAction.label}
                    url={callToAction.url}
                    analyticsId={callToAction.label}
                    analyticsEventName='secondaryButtonClick'
                />
            )}
        </Box>
    )
}
