import { Box, Flex } from '@chakra-ui/react'
import { Stepper as IrishStepper } from '@irishlifedigitalhub/ds.molecules.stepper'
import React, { useEffect, useState } from 'react'
import ChevronRightIcon from './ChevronRightIcon.svg'
import ChevronLeftIcon from './ChevronLeftIcon.svg'
import './stepper.css'

export interface StepperProps {
    steps: number
    spacing?: number
    defaultStep?: number
    leftIndicator?: boolean
    rightIndicator?: boolean
    vertically?: boolean
    onChange: (number: number) => void
}

export const Stepper = ({
    steps,
    spacing = 1,
    defaultStep = 1,
    leftIndicator = false,
    rightIndicator = false,
    vertically = false,
    onChange,
}: StepperProps) => {
    const [index, setIndex] = useState(defaultStep)

    useEffect(() => {
        setIndex(defaultStep)
    }, [defaultStep])

    React.useEffect(() => {
        if (index === 0) {
            setIndex(steps)
            return
        } else if (index > steps) {
            setIndex(1)
            return
        }
        onChange(index)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, steps])

    return (
        <Flex
            width='min-content'
            alignItems='center'
            justifyContent='center'
            transform={vertically ? 'rotate(90deg)' : ''}
        >
            <Box
                cursor='pointer'
                onClick={() => setIndex(index - 1)}
                marginRight='22px'
                marginTop={vertically ? '' : '2px'}
                w='16px'
                h='16px'
            >
                <img src={ChevronLeftIcon} alt='iconLeft' />
            </Box>
            <div className={vertically ? 'vertically-stepper' : ''}>
                <IrishStepper
                    defaultStep={index}
                    spacing={spacing}
                    steps={steps}
                    onChange={(number: number) => setIndex(number)}
                    {...(rightIndicator
                        ? { rightIndicator }
                        : { leftIndicator })}
                />
            </div>
            <Box
                cursor='pointer'
                onClick={() => setIndex(index + 1)}
                marginLeft='12px'
                w='16px'
                h='16px'
            >
                <img src={ChevronRightIcon} alt='iconRight' />
            </Box>
        </Flex>
    )
}

  
