import {
    TestimonialSection,
    TestimonialSectionProps,
} from '@irishlifedigitalhub/ds.organisms.testimonial-section'
import { navigate } from '@reach/router'
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'
import React from 'react'

export const Testimonials: React.FC<TestimonialSectionProps> = (data) => {
    const props = getTestimonialProps(data)

    return <TestimonialSection {...props} />
}

Testimonials.displayName = 'Testimonials'

export const getTestimonialProps = (data: any): TestimonialSectionProps => {
    const testimonial = data.testimonials[0]

    const type =
        testimonial.type === 'testimonialcustomer_type' ? 'customer' : 'advisor'
    const cta = testimonial[`testimonial${type}__cta`]?.[0]

    const onClick = () => {
        fireAnalyticsEvent({
            name: 'testimonialButtonClick',
            properties: {
                clickText: cta.cta__label,
                clickURL: cta.cta__url,
                clickID: cta.cta__analytics_id,
            },
        })
        navigate(cta.cta__url)
    }

    const buttonProps = cta?.cta__label
        ? {
              text: cta.cta__label,
              onClick,
          }
        : undefined

    const commonProps = {
        type: type as TestimonialSectionProps['type'],
        name: testimonial[`testimonial${type}__name`],
        avatarSrc: testimonial[`testimonial${type}__avatar`],
        quote: testimonial[`testimonial${type}__quote`],
        button: buttonProps,
        title: testimonial[`testimonial${type}__title`],
        role: testimonial[`testimonial${type}__role`],
    }

    if (type === 'customer') {
        return {
            ...commonProps,
            location: testimonial.testimonialcustomer__location,
        }
    }

    return commonProps as TestimonialSectionProps
}
