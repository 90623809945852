import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import React from 'react'
import { Box } from '@chakra-ui/react'

export interface TextWithTitleProps {
    title: string
    body: string
}

export const TextWithTitle: React.FC<TextWithTitleProps> = ({
    body,
    title,
}) => {
    return (
        <Box w={'100%'} bg={'white'} p={'36px'}>
            <Text
                variant={'title-lg'}
                color={'var(--blue-600-primary, #5261AC)'}
                pb={'20px'}
            >
                {title}
            </Text>
            <Text variant={'body-md'}>{body}</Text>
        </Box>
    )
}
