import {
    Box,
    SimpleGrid,
    VStack,
    Grid,
    GridItem,
    HStack,
} from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import {
    DocumentDownloadIcon,
    DownloadIcon,
} from '@irishlife/ilgroupdesignsystem.icons'
import { Link } from 'common/components/atoms/Link'
import React from 'react'

interface DocumentDownloadPanelProps {
    title: string
    cards: { title: string; fileSize: string; downloadPath: string }[]
}

const DocumentDownloadPanel: React.FC<DocumentDownloadPanelProps> = (props) => {
    const { title, cards } = props

    return (
        <Box>
            <VStack justify='center' alignItems='center'>
                <Box mb={16} w={['auto', 'auto', '65%']}>
                    <Text
                        variant='title-xl'
                        fontWeight='medium'
                        color='#3A3160'
                        textAlign='center'
                    >
                        {title}
                    </Text>
                </Box>

                <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={6}>
                    {cards?.map((item: any, index: number) => (
                        <Card
                            style={{
                                padding: '15px 12px',
                            }}
                        >
                            <Grid
                                templateColumns='repeat(12, 1fr)'
                                alignContent='center'
                                justifyContent='center'
                                h={'100%'}
                            >
                                <GridItem colSpan={2}>
                                    <HStack justifyContent='center' h='100%'>
                                        <DocumentDownloadIcon
                                            fontSize={36}
                                            color={'#717FE2'}
                                        />
                                    </HStack>
                                </GridItem>
                                <GridItem colStart={3} colEnd={11}>
                                    <Box px='16px'>
                                        <Box display='block'>
                                            <Text
                                                color={'#0C1E68'}
                                                fontWeight='semibold'
                                                style={{
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                {item.title}
                                            </Text>
                                        </Box>
                                        <Box display='block'>
                                            <Text
                                                color={'#766F90'}
                                                fontWeight={'bold'}
                                                fontSize={'12px'}
                                                style={{
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                {item.fileSize} file size
                                            </Text>
                                        </Box>
                                    </Box>
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <HStack
                                        justifyContent='center'
                                        h='100%'
                                        cursor={'pointer'}
                                    >
                                        <Link
                                            path={''}
                                            onClick={() => {
                                                //open link in new tab
                                                window.open(
                                                    item.downloadPath,
                                                    '_blank'
                                                )
                                            }}
                                        >
                                            <DownloadIcon
                                                fontSize={24}
                                                color={'#717FE2'}
                                            />
                                        </Link>
                                    </HStack>
                                </GridItem>
                            </Grid>
                        </Card>
                    ))}
                </SimpleGrid>
            </VStack>
        </Box>
    )
}

export default DocumentDownloadPanel
