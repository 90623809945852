import { Box, Grid, Image, Text } from '@chakra-ui/react'
import { navigate } from '@reach/router'
import React from 'react'
import CustomPoplink from '../../molecules/CustomPoplink/CustomPoplink'

export interface Items {
    icon: string
    title: string
    url: string
}

export interface ResourcesWithLinksProps {
    title: string
    subtitle: string
    description: string
    poplink: Items[]
}

const JsxIcon = (imageUrl: string) => (
    <Image
        as='img'
        height='100%'
        src={imageUrl}
        alt='Sample Image'
        style={{ width: '24px', height: '24px' }}
    />
)

export const ResourcesWithLinks: React.FC<ResourcesWithLinksProps> = (
    props
) => {
    const { title, subtitle, description, poplink = [] } = props

    return (
        <Box data-testid='resources' as='section'>
            <Grid templateColumns={{ md: '4fr 2fr 6fr' }} alignItems='center'>
                <Box mb={{ base: 8, md: 0 }}>
                    <Text
                        mb={8}
                        color='#4DB7AB'
                        as='h1'
                        fontSize='md'
                        fontWeight={700}
                        textTransform='uppercase'
                    >
                        {title}
                    </Text>
                    <Text
                        mb={8}
                        fontSize={{ base: '2xl', md: '4xl' }}
                        color='#3A3160'
                        fontWeight={500}
                    >
                        {subtitle}
                    </Text>
                    {description && (
                        <Text fontSize='18px' color='#766F90'>
                            {description}
                        </Text>
                    )}
                </Box>

                <Box display={{ base: 'none', md: 'block' }}></Box>

                <Box
                    display={{ base: '', md: 'flex' }}
                    flexDirection={{ base: 'unset', md: 'column' }}
                    justifyContent={{ base: 'unset', md: 'flex-end' }}
                >
                    <Grid templateColumns='1fr' gap={4} alignItems='flex-end'>
                        {poplink &&
                            poplink.map((item, index) => (
                                <Box
                                    borderBottom='1px solid #EBEDF7'
                                    mb={index === poplink.length - 1 ? 0 : 4}
                                    key={'CustomPoplink' + index}
                                >
                                    <CustomPoplink
                                        title={item.title}
                                        icon={JsxIcon(item.icon)}
                                        handleClick={() => navigate(item.url)}
                                    />
                                </Box>
                            ))}
                    </Grid>
                </Box>
            </Grid>
        </Box>
    )
}
