import { IconButton } from '@irishlife/ilgroupdesignsystem.atoms.icon-button'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@irishlife/ilgroupdesignsystem.icons'
import { Box, Progress as LinearProgress, HStack } from '@chakra-ui/react'
import React, { useState, useEffect, useMemo } from 'react'

interface PaginationProps {
    items: any
    itemsPerPage?: number
    callBack: (object: any) => void
}
export const Pagination: React.FC<PaginationProps> = ({
    items,
    itemsPerPage = 4,
    callBack,
}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const totalPages = Math.ceil(items.length / itemsPerPage)
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const visibleItems = items.slice(startIndex, endIndex)
    const progressBarValue = useMemo(() => {
        const progressValue = (currentPage / totalPages) * 100
        if (progressValue === Infinity) {
            return 100
        }
        return progressValue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, totalPages, items])

    useEffect(() => {
        callBack({ visibleItems, currentPage })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, items, itemsPerPage])

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1)
        }
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1)
        }
    }
    return (
        <Box gap={6}>
            <Box>
                <LinearProgress
                    size='xs'
                    value={progressBarValue}
                    sx={{
                        '& > div': {
                            backgroundColor: '#717FE2',
                        },
                    }}
                />
            </Box>
            <HStack justifyContent='center' mt='32px'>
                <IconButton
                    aria-label='Previous page'
                    icon={<ChevronLeftIcon />}
                    size='sm'
                    mr='32px'
                    isDisabled={currentPage === 1}
                    onClick={handlePrevPage}
                />
                <IconButton
                    aria-label='Next page'
                    icon={<ChevronRightIcon />}
                    size='sm'
                    ml='32px'
                    isDisabled={currentPage === totalPages || totalPages === 0}
                    onClick={handleNextPage}
                />
            </HStack>
        </Box>
    )
}
