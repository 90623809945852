import { Grid } from '@material-ui/core'
import numeral from 'numeral'
import React from 'react'
import { Legend } from '../../../legend'
import { pattern } from '../utils/pattern'

export interface LegendDisplayProps {
    projection: number
    statePension: number
}

export const LegendDisplay: React.FC<LegendDisplayProps> = ({
    projection,
    statePension,
}) => {
    return (
        <>
            <Grid
                item
                xs={6}
                md={12}
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <Legend
                    label='Your Pension'
                    color='#5261AC'
                    value={`€${numeral(projection.toString()).format('0,000')}`}
                />
                <Legend
                    label='State Pension'
                    fill={pattern}
                    value={`€${numeral(statePension.toString()).format(
                        '0,000'
                    )}`}
                />
            </Grid>
        </>
    )
}
