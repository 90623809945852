// write a bolier plate for a functional component
import React, { FC, useState } from 'react'
import { Box, SimpleGrid } from '@chakra-ui/react'
import { Pagination } from '../Pagination'
import { Card } from '../Card'

interface PaginatedCardsProps {
    icon: string
    description: string
}

export const PaginatedCards: FC<{ cards: PaginatedCardsProps[] }> = ({
    cards,
}) => {
    const [visibleItems, setVisibleItems] = useState<PaginatedCardsProps[]>([])

    const handlePagination = (array: any) => {
        setVisibleItems(array.visibleItems)
    }

    return (
        <>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={4}>
                {visibleItems.map((card, idx) => (
                    <Box key={idx + '-card'}>
                        <Card
                            style={{
                                background: 'var(--White, #FFF)',
                                boxShadow:
                                    '4px 0px 16px 0px rgba(82, 97, 172, 0.15)',
                                padding: '36px',
                            }}
                            icon={card.icon}
                            text={card.description || ''}
                            title={''}
                        />
                    </Box>
                ))}
            </SimpleGrid>
            <Box pt={16}>
                <Pagination
                    items={cards}
                    itemsPerPage={3}
                    callBack={handlePagination}
                />
            </Box>
        </>
    )
}
