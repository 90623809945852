import { Box, Image, SimpleGrid, useMediaQuery, VStack } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React, { useEffect, useState } from 'react'
import { Stepper } from '../../molecules/StepperWithArrows/index'
interface CardData {
    icon: string
    text?: string
    image?: string
    imageTitle?: string
    imageText?: string
    title: string
}

interface MultiCardGalleryWithStepperProps {
    title: string
    cards: CardData[]
}

export const MultiCardGalleryWithStepper: React.FC<MultiCardGalleryWithStepperProps> =
    (props) => {
        const { title, cards } = props

        const [selectedCardIndex, setSelectedCardIndex] = useState(0) // State to track selected card index
        const [currentPage, setCurrentPage] = useState(
            Math.floor(selectedCardIndex) / 3 + 1
        )
        const [visibleItems, setVisibleItems] = useState<CardData[]>()

        const handleCardClick = (idx: number) => {
            const index = (currentPage - 1) * 3 + idx
            setSelectedCardIndex(index) // Update selected card index on click
        }

        const cardStyle = (index: number) => ({
            borderBottom:
                index === selectedCardIndex
                    ? ' 6px solid #039FBF'
                    : '2px solid transparent',
        })

        const [isMobile] = useMediaQuery('(max-width: 720px)')

        useEffect(() => {
            const startIndex = (currentPage - 1) * 3
            const endIndex = startIndex + 3
            setVisibleItems(cards.slice(startIndex, endIndex))
        }, [currentPage, cards])

        useEffect(() => {
            setCurrentPage(Math.floor(selectedCardIndex / 3) + 1)
        }, [selectedCardIndex])

        const currentCard = cards[selectedCardIndex]
        return (
            <VStack align='stretch'>
                <Box>
                    <Text
                        mx='auto'
                        align='center'
                        variant={isMobile ? 'title-lg' : 'title-xl'}
                        fontWeight={isMobile ? 'normal' : 'medium'}
                        width={isMobile ? '100%' : '50%'}
                        color='#4E4670'
                    >
                        {title}
                    </Text>
                </Box>

                {!isMobile ? (
                    <Box py='64px' style={{ marginTop: '0px' }}>
                        <SimpleGrid columns={3} spacing={4}>
                            {visibleItems?.map((card, index) => (
                                <Card
                                    style={{
                                        ...cardStyle(
                                            (currentPage - 1) * 3 + index
                                        ),
                                        minHeight: '215px',
                                    }}
                                    onClick={() => handleCardClick(index)}
                                >
                                    <Box>
                                        <Image
                                            src={card.icon}
                                            w={9}
                                            h={9}
                                            mr={2}
                                        />
                                        <Box pt={8}>
                                            <Text
                                                fontWeight='medium'
                                                color='#766F90'
                                            >
                                                {card.title}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Card>
                            ))}
                        </SimpleGrid>
                    </Box>
                ) : (
                    <SimpleGrid py={8} style={{ marginTop: '0px' }}>
                        <Card
                            style={{
                                minHeight: '215px',
                            }}
                        >
                            <Box width='100%'>
                                <Image
                                    src={currentCard.icon}
                                    w={9}
                                    h={9}
                                    mr={2}
                                />
                                <Box pt={8}>
                                    <Text fontWeight='medium' color='#766F90'>
                                        {currentCard.title}
                                    </Text>
                                </Box>
                            </Box>
                        </Card>
                    </SimpleGrid>
                )}

                <Box>
                    <SimpleGrid columns={isMobile ? 1 : 2} alignItems='stretch'>
                        <Box display={isMobile ? 'none' : 'block'}>
                            <Image src={currentCard?.image} width='100%' />
                        </Box>
                        <Box>
                            <Box
                                {...(isMobile
                                    ? { pl: '0' }
                                    : {
                                          pl: '119px',
                                          h: '100%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                      })}
                            >
                                <Box
                                    style={
                                        isMobile
                                            ? {
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  marginBottom: '32px',
                                              }
                                            : {
                                                  display: 'flex',
                                                  justifyContent: 'flex-end',
                                              }
                                    }
                                >
                                    <Stepper
                                        steps={cards.length}
                                        leftIndicator
                                        defaultStep={selectedCardIndex + 1}
                                        onChange={(number) =>
                                            setSelectedCardIndex(number - 1)
                                        }
                                    />
                                </Box>
                                <Box color='#766F90'>
                                    <Text
                                        variant='title-sm'
                                        fontWeight='medium'
                                    >
                                        {currentCard.title}
                                    </Text>
                                    <Box pt={8}>
                                        <Text fontWeight='medium'>
                                            {currentCard.text}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </SimpleGrid>
                </Box>
            </VStack>
        )
    }
