import { createIcon } from '@chakra-ui/react'
import React from 'react'
const AddCircleIcon = createIcon({
    displayName: 'AddCircleIcon',
    viewBox: '0 0 24 24',
    path: [
        <path
            key='circle'
            clipRule='evenodd'
            d='M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75 12 5.787 23.25 12 23.25Z'
            stroke='currentColor'
            fill='transparent'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />,
        <path
            clipRule='evenodd'
            key='plus'
            stroke='currentColor'
            fill='currentColor'
            d='M17 12H7M12 17V7'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />,
    ],
})

AddCircleIcon.displayName = 'AddCircleIcon'

export default AddCircleIcon
