import { Box } from '@chakra-ui/react'
import {
    FeatureList,
    FeatureListProps
} from 'common/components/organisms/FeatureList'
import React from 'react'

export interface TextWithImageProps extends FeatureListProps {}

export const TextWithImage: React.FC<TextWithImageProps> = props => {
    const data = getTextWithImageData(props)
    return (
        <Box mb={data.variant === 'top' ? { base: '-96px', md: '-144px' } : {}}>
            <FeatureList {...data} />
        </Box>
        // <FeatureList {...data} />
    )
}

export const getTextWithImageData = (data: any): TextWithImageProps => {
    return {
        featureTitle: data.featurelist__bullets_title,
        featureSubtitle: data.featurelist__bullets_subtitle,
        features: data.featurelist__features,
        image: data.featurelist__image,
        subtitle: data.featurelist__subtitle,
        title: data.featurelist__title,
        variant: data.featurelist__variant,
        cta: data.featurelist__cta,
        secondaryTitle: data.secondary_title,
        callToAction: {
            analyticsId: data.featurelist__cta_analyticsid,
            label: data.featurelist__cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.featurelist__cta_icon,
            url: data.featurelist__cta_url,
            variant: data.featurelist__cta_variant,
            noSpacer: true
        }
    }
}
