import React from 'react'
import { Box } from '@chakra-ui/react'
import { InsuranceIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { isDesktop } from 'react-device-detect'
export interface MetamoCardProps {
    item: ItemProps
    onClick: (value: ItemProps) => void
}
export interface ItemProps {
    branchName: string
    branchId: string
    icon?: string
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectedCard: {
            color: '#9CA5EB',
            '&:focus': {
                borderBottom:
                    '6px solid var(--highlight-pacific-blue, #039FBF)',
                color: '#039FBF !important',
            },
        },
    })
)
export const MetamoCard: React.FC<MetamoCardProps> = ({ item, onClick }) => {
    const classes = useStyles()
    const desktop = isDesktop
    return (
        <Box
            as='button'
            onClick={() => onClick(item)}
            w={
                desktop
                    ? ['112px', '124px', '178px', '182px', '222px']
                    : '222px'
            }
            alignItems={'center'}
            maxHeight={'100%'}
            display='flex'
            bg={'#fff'}
            flexDirection='column'
            borderWidth='1px'
            borderRadius='lg'
            p={'24px 6px'}
            boxShadow={'0px 0px 20px 0px rgba(82, 97, 172, 0.24)'}
            className={classes.selectedCard}
        >
            {item?.icon && (
                <img
                    width={'72px'}
                    height={'72px'}
                    alt='branch icon'
                    src={item?.icon}
                />
            )}
            {!item?.icon && (
                <InsuranceIcon
                    bg='vibrant.50'
                    w={'72px'}
                    h={'72px'}
                    fontSize={48}
                    display='flex'
                    alignItems={'flex-start'}
                    borderRadius={'100%'}
                    className={classes.selectedCard}
                />
            )}
            <Text
                display='flex'
                flexDirection='column'
                color={'brand.700'}
                textAlign={'center'}
                variant={'title-sm'}
            >
                {item?.branchName}
            </Text>
        </Box>
    )
}
