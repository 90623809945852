import { Box } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface IframeProps {
    src?: string
    width?: string
    height?: number
    mobileHeight?: number
    title: string
    scrolling: string
    heading?: string
    text?: string
}

const fullWidthWrapperStyles = {
    marginLeft: '-50vw',
    marginRight: '-50vw',
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    display: 'flex',
    justifyContent: 'center'
}

export const Iframe: React.FC<IframeProps> = props => {
    const attributes = getIframeData(props)
    const desktop = useDesktop()

    const [expanded, setExpanded] = React.useState(false)
    // 0, 480, 768, 992, 1280, 1536
    const heights = {
        collapsed: [2450, 2300, 2050, 2000, 1900],
        expanded: [4550, 3700, 3200, 3100, 2900]
    }

    const handleIframeMessage = (event: any) => {
        if (
            JSON.stringify(event.data) === 'true' ||
            JSON.stringify(event.data) === 'false'
        ) {
            setExpanded(event.data)
        }
    }

    React.useEffect(() => {
        if (typeof window === 'undefined') return
        window.addEventListener('message', handleIframeMessage)
        return () => {
            if (typeof window === 'undefined') return
            window.removeEventListener('message', handleIframeMessage)
        }
    }, [])

    return (
        <>
            {attributes.heading && (
                <Text
                    as='h2'
                    lineHeight={desktop ? 'initial' : '40px'}
                    fontSize='40px'
                    color='#3A3160'
                    textAlign='center'
                    mb={8}
                >
                    {attributes.heading}
                </Text>
            )}
            {attributes.text && (
                <Text as='p' fontSize='20px' color='#766F90' textAlign='center'>
                    {attributes.text}
                </Text>
            )}
            <Box
                data-testid='Iframe'
                display='flex'
                sx={fullWidthWrapperStyles}
                height={heights[expanded ? 'expanded' : 'collapsed']}
                style={{ transition: 'height 1s ease-in-out' }}
            >
                <iframe
                    title={attributes.title}
                    src={attributes.src}
                    width={attributes.width}
                    height='100%'
                    scrolling={
                        attributes.scrolling
                            ? attributes.scrolling.toLowerCase()
                            : 'auto'
                    }
                />
            </Box>
        </>
    )
}

const getIframeData = (data: any): IframeProps => {
    return {
        src: data.iframe__src,
        title: data.iframe__title,
        width: data.iframe__width,
        height: data.iframe__height,
        mobileHeight: data.iframe__mobile_height,
        scrolling: data.iframe__scrolling,
        heading: data.iframe__heading,
        text: data.iframe__text
    }
}
