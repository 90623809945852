import React from "react";
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { Box } from '@chakra-ui/react'
import { RichText } from "common/components/molecules/RichText";

interface ITable {
    icon?: string;
    title: string;
    rows: IRow[];
}

interface IRow {
    icon?: string;
    text: string;
}

export interface IBasicTable {
    title?: string;
    subtitle?: string;
    tables?: ITable[];
    footer_text?: string;
}

const BasicTable: React.FC<any> = (props: IBasicTable) => {
    
    return <Box
    display={"flex"}
    flexDirection={"column"}
    width={"100%"}
    gap={"32px"}>
    
        {(props.title || props.subtitle) &&
            <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            gap={"12px"}>

                {props.title &&
                    <Text
                        style={{
                            color: '#1D2F7C',
                            fontSize: '32px',
                            fontWeight: 400,
                        }}
                        >
                    { props.title }
                    </Text>
                }

                {props.subtitle &&
                    <Text
                        style={{
                            color: '#615A80',
                            fontSize: '18px',
                            lineHeight: '23px',
                            fontWeight: 600,
                        }}
                    >
                    { props.subtitle }
                    </Text>
                }
            </Box>
        }

        {props.tables && props.tables.length > 0 &&
            <Box
            display={"flex"}
            width={"100%"}
            flexDirection={"column"}
            gap={"32px"}
            >
                { props.tables.map((table, index) => ( <Table key={`basicTable_${index}`} tableIndex={index} {...table} /> )) }
            </Box>
        }

        {props.footer_text &&
            <Text
                style={{
                    marginBottom: '32px',
                    color: '#615A80',
                    lineHeight: '28px',
                    fontSize: '18px',
                    fontWeight: 400,
                }}
            >
                { props.footer_text }
            </Text>
        }
    </Box>;
}

const Table = (table: ITable & {tableIndex: number}) => {

    const normalizePhoneAndMailHyperlink = (text: string) => {
        const phoneUrl = '<a href="http://tel:';
        const phoneRegex = new RegExp(phoneUrl, 'g');
        const mailUrl = '<a href="http://mailto:';
        const mailRegex = new RegExp(mailUrl, 'g');

        return text.replace(phoneRegex, '<a href="tel:').replace(mailRegex, '<a href="mailto:');
    }

    return <Box
    display={"flex"}
    flexDirection={"column"}
    width={"100%"}
    >
        {/* Header */}
        <Box
        display={"flex"}
        borderRadius={'12px 12px 0px 0px'}
        backgroundColor={'var(--Vibrant-50, #EEF0FC)'}
        minHeight={'60px'}
        alignItems={'center'}
        >
            <Box display={'flex'} width={'64px'} justifyContent={'center'} padding={'16px'}>
                {table.icon &&
                    <img src={table.icon} alt="Icon" style={{maxWidth: '40px', maxHeight: '40px'}} />
                }
            </Box>
            <Box width={'100%'} flex={1} padding={'16px'}>
                <Text
                    style={{
                        color: 'var(--Brand-600, #5261AC)',
                        lineHeight: '16px',
                        fontSize: '16px',
                        fontWeight: 700,
                        textTransform: 'uppercase'
                    }}
                >
                { table.title }
                </Text>
            </Box>
        </Box>
        {/* Header */}

        {/* Content */}
        {table.rows && table.rows.length > 0 && table.rows.map((row, index) => (
            <Box
            key={`basicTable_${table.tableIndex}_row_${index}`}
            data-testid={'basic-table'}
            display={"flex"}
            borderRadius={index + 1 === table.rows.length ? '0px 0px 12px 12px' : '0px'}
            backgroundColor={'#FFFFFF'}
            minHeight={'60px'}
            alignItems={'center'}
            borderBottom={'1px'} borderBottomColor={'var(--Vibrant-100, #E3E5F9)'}
            >
                <Box display={'flex'} width={'64px'} justifyContent={'center'} padding={'16px'}>
                    {row.icon &&
                        <img src={row.icon} alt="Icon" style={{maxWidth: '40px', maxHeight: '40px'}} />
                    }
                </Box>
                <Box width={'100%'} flex={1} padding={'16px'}>
                    <RichText html style={{color: '#0C1E68', fontWeight: 500}} label={normalizePhoneAndMailHyperlink(row.text)} />
                </Box>
            </Box>
        ))
        }
        {/* Content */}
    </Box>
}

export { BasicTable };