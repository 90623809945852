import { Box, Stack, VStack } from '@chakra-ui/react'
import CSS from 'csstype'
import React from 'react'

export interface ContainerRightProps {
    image: string
}

export const ContainerRight: React.FC<any> = ({ image }) => {
    const skewObjectStyleVertical: CSS.Properties = {
        position: 'absolute',
        left: '0',
        top: '0',
        height: '100%',
        background:
            'linear-gradient(to right top, #F7F8FB 0%, #F7F8FB 50%, transparent 51%, transparent 70%, transparent 100%)',
        zIndex: 0,
    }
    return (
        <VStack alignItems='flex-start'>
            <Stack
                py={'18px'}
                width='577px'
                backgroundImage={`url("${image}?auto=format")`}
                backgroundPosition='center'
                backgroundSize='cover'
                backgroundRepeat={'no-repeat'}
                height='380px'
                position={'relative'}
                marginLeft={[
                    '0px !important',
                    '0px !important',
                    '12px !important',
                ]}
            >
                <Box style={skewObjectStyleVertical} w='67px'></Box>
            </Stack>
            <Stack></Stack>
        </VStack>
    )
}
