import { MultiFeatureList } from 'common/components/organisms/MultiFeatureList/index'
import React, { FC } from 'react'

const MultiFeatureListWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <MultiFeatureList {...processedProps} />
}
export { MultiFeatureListWrapper as MultiFeatureList }
const getProps = (data: any) => {
    return {
        title: data.title,
        caption: data.caption,
        items: data.textwithimages.map((item: any) => {
            return {
                featureTitle: item.featurelist__bullets_title,
                featureSubtitle: item.featurelist__bullets_subtitle,
                features: item.featurelist__features,
                image: item.featurelist__image,
                subtitle: item.featurelist__subtitle,
                title: item.featurelist__title,
                variant: item.featurelist__variant,
                cta: item.featurelist__cta,
                secondaryTitle: item.secondary_title,
                callToAction: {
                    analyticsId: item.featurelist__cta_analyticsid,
                    label: item.featurelist__cta_label,
                    [`${item.call_to_action__iconlocation}Icon`]:
                        item.featurelist__cta_icon,
                    url: item.featurelist__cta_url,
                    variant: item.featurelist__cta_variant,
                    noSpacer: true,
                },
            }
        }),
    }
}
