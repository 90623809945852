import React from 'react'
// import './SupportCenter.css'

const SupportCenter: React.FC = () => {
    // useEffect(() => {
    //     const head = document.querySelector('head')
    //     setTimeout(() => {
    //         if (head) {
    //             // Added null check for head
    //             head.insertAdjacentHTML(
    //                 'beforeend',
    //                 `<link rel="stylesheet" href="https://nr-customers.s3.amazonaws.com/PS/ajmila/buttons/FunctionalArticle.css" />`
    //             )
    //         }
    //     }, 1000)
    //     stylesObserver()
    // }, [])

    return <div id='genesys-support-center'></div>
}

export default SupportCenter
