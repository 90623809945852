import { Box } from '@chakra-ui/react'
import { EmbedCollegeCostsCalculator } from '@multiply-ai/college-costs-calculator'
import '@multiply-ai/college-costs-calculator/dist/style.css'
import {
    COLLEGE_COSTS_CALCULATOR_DIGITAL_ADVICE_URL,
    COLLEGE_COSTS_CALCULATOR_GRAPHQL_ENDPOINT,
} from 'core'
import React from 'react'

export interface CollegeCostsCalculatorProps {}

export const CollegeCostsCalculator: React.FC<CollegeCostsCalculatorProps> =
    () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <EmbedCollegeCostsCalculator
                    digitalAdviceUrl={
                        COLLEGE_COSTS_CALCULATOR_DIGITAL_ADVICE_URL
                    }
                    graphQLEndpoint={COLLEGE_COSTS_CALCULATOR_GRAPHQL_ENDPOINT}
                />
            </Box>
        )
    }
