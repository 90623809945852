import { Box } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import React from 'react'
import {
    JumbotronCallToAction,
    JumbotronCallToActionProps
} from '../../Jumbotron/JumbotronCallToAction'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CtaProps extends JumbotronCallToActionProps {
    title?: string
    subtitle?: string
}

export const Cta: React.FC<CtaProps> = props => {
    const { title, subtitle, ...rest } = getCtaData(props)
    return (
        <Box display='flex' flexDirection='column' alignItems='center'>
            {title && <Text label={title} variant='h2' />}
            {subtitle && (
                <Text box={{ mt: 2 }} label={subtitle} variant='subtitle1' />
            )}
            {(title || subtitle) && <Box mt={4} />}
            <Box>
                <JumbotronCallToAction {...rest} />
            </Box>
        </Box>
    )
}

const getCtaData = (data: any): CtaProps => {
    return {
        title: data.title,
        subtitle: data.subtitle,
        analyticsId: data.call_to_action__analytics_id,
        label: data.call_to_action__label,
        [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
        text: data.call_to_action__text,
        url: data.call_to_action__url,
        variant: data.call_to_action__variant
    }
}
