import React from 'react'
import { Box, Flex, VStack, Square } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { useIncomeProtectionCalculatorCalculator } from '../utils/useIncomeProtectionCalculator'

export default function Charts() {
    const {
        state: { income, coveredIncome, stateCover },
    } = useIncomeProtectionCalculatorCalculator()
    const amountNotCovered = income - coveredIncome - stateCover
    const heightOfNotCovered = (amountNotCovered / income) * 183
    const heightOfCovered = (coveredIncome / income) * 183
    const heightOfStateCovered = (stateCover / income) * 183
    return (
        <Flex>
            <VStack align={'start'} h={'183px'}>
                <Box
                    mr={'24px'}
                    borderRadius={'8px 8px 0px 0px'}
                    backgroundColor={'var(--Monochrome-200, #D8D6DF)'}
                    h={`${heightOfNotCovered}px`}
                    w={'64px'}
                />
                <Box
                    mr={'24px'}
                    backgroundColor={'var(--Vibrant-200, #CFD4FA)'}
                    h={`${heightOfCovered}px`}
                    w={'64px'}
                />
                <Box
                    mr={'24px'}
                    borderRadius={'0px 0px 8px 8px'}
                    backgroundColor={'var(--Vibrant-700, #5E6CDA)'}
                    h={`${heightOfStateCovered}px`}
                    w={'64px'}
                />
            </VStack>
            <VStack align={'start'}>
                <Flex mt={'0px'}>
                    <Square
                        size={'22px'}
                        backgroundColor={'var(--Monochrome-200, #D8D6DF)'}
                    ></Square>
                    <Box ml={'12px'}>
                        <Text variant={'body-md'}>Not covered</Text>
                        <Text
                            variant={'subtitle-md'}
                            color={'var(--Brand-800, #1D2F7C)'}
                        >
                            {`€${amountNotCovered.toLocaleString()}`}
                        </Text>
                    </Box>
                </Flex>
                <Flex mt={'0px !important'}>
                    <Square
                        size={'22px'}
                        backgroundColor={'var(--Vibrant-200, #CFD4FA)'}
                    ></Square>

                    <Box ml={'12px'}>
                        <Text variant={'body-md'}>
                            Covered by your income <br /> protection benefit
                        </Text>
                        <Text
                            variant={'subtitle-md'}
                            color={'var(--Brand-800, #1D2F7C)'}
                        >
                            {`€${coveredIncome.toLocaleString()}`}
                        </Text>
                    </Box>
                </Flex>
                <Flex mt={'0px !important'}>
                    <Square
                        size={'22px'}
                        backgroundColor={'var(--Vibrant-700, #5E6CDA)'}
                    ></Square>

                    <Box ml={'12px'}>
                        <Text variant={'body-md'}>Covered by the state</Text>
                        <Text
                            variant={'subtitle-md'}
                            color={'var(--Brand-800, #1D2F7C)'}
                        >
                            {`€${stateCover.toLocaleString()}`}
                        </Text>
                    </Box>
                </Flex>
            </VStack>
        </Flex>
    )
}
