import { Box } from '@chakra-ui/react'
import React from 'react'
import { IncomeProtectionCalculatorOutput } from './components/IncomeProtectionCalculatorOutput'
import { ValidityCard } from './components/ValidityCard'
// import { HaveAQuestionCard } from './components/HaveAQuestion'
import { useCalculatorMachine } from '../../../utils/context'
import { AdvisorBooking } from 'common/components/organisms/AdvisorBooking'
import { BenefitsCard } from '../common/BenefitsCard'
export interface IncomeProtectionQuoteProps {
    props?: any
}
export const IncomeProtectionQuote: React.FC<IncomeProtectionQuoteProps> =
    () => {
        const { state, send } = useCalculatorMachine()
        if (state.context.quoteData?.error) {
            send({ type: 'ERROR OCCURED' })
        }
        const benefits = [
            `98.1% of death claims paid (2022)`,
            `Supporting 7,000+ families with claims (2022)`,
            `80 years looking after the needs of people in Ireland`,
        ]
        const benefitsTitle = `Why choose Irish Life Assurance?`

        return (
            <Box>
                <IncomeProtectionCalculatorOutput />
                <ValidityCard />
                {/* <HaveAQuestionCard /> */}
                <AdvisorBooking slim />
                <BenefitsCard benefits={benefits} title={benefitsTitle} />
            </Box>
        )
    }
