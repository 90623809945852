import React, { FC } from 'react'
import { MiniFooter } from 'common/components/organisms/MiniFooter/index'

const MiniFooterWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <MiniFooter {...processedProps} />
}
export { MiniFooterWrapper as MiniFooter }
const getProps = (props: any) => {
    return {
        loginLink: props.login_link,
    }
}
