import React, { FC } from 'react'
import { Campaign3DSlideshow } from 'common/components/organisms/Campaign3DSlideshow/index'

const Campaign3DSlideshowWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <Campaign3DSlideshow {...processedProps} />
}
export { Campaign3DSlideshowWrapper as Campaign3DSlideshow }
const getProps = (props: any) => {
    const { title, untitled_linked_items: items } = props
    
    return {
        title,
        cards: items.map((item: any) => ({
            title: item.title,
            subtitle: item.subtitle,
            subtitle2: item.subtitle_2,
            paragraph: item.description_b57cf5a || item.description,
            imageSrc: item.file,
            callToAction: {
                label: item.button_c9ad60e__text,
                url: item.button_c9ad60e__url,
                variant: item.card_cta_0396ba7__cta_variant,
            },
        })),
    }
}
