import { Box, Image, SimpleGrid } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { navigate } from '@reach/router'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

interface ImageWithParagraphProps {
    image: string
    title: string
    callToAction?: any
}

export const ImageWithParagraph: React.FC<ImageWithParagraphProps> = (
    props
) => {
    const { image, title, callToAction } = props

    const isMobile = !useDesktop()
    return (
        <Box>
            <SimpleGrid columns={{ sm: 1, md: 2 }} alignItems='flex-end'>
                <Image
                    src={`${image}?auto=format`}
                    alt='Sample Image'
                    maxHeight='400px'
                    display={isMobile ? 'none' : ''}
                    width='100%'
                    objectFit={'cover'}
                />
                <Box pl={isMobile ? '16px' : '119px'} pr='16px'>
                    <Box color='#766F90' fontSize='16px' fontWeight={500}>
                        {title}
                    </Box>
                    <Box>
                        <Button
                            onClick={() => navigate(callToAction.url)}
                            {...callToAction}
                            mt='32px'
                        >
                            {callToAction.label}
                        </Button>
                    </Box>
                </Box>
            </SimpleGrid>
        </Box>
    )
}
