import React from 'react'
import { Box, HStack } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
export interface QuoteCardHeadersProps {
    columns: any
    columnClass: any
    handleHeaderClick: any
}
export const QuoteCardHeaders: React.FC<QuoteCardHeadersProps> = ({
    columns,
    columnClass,
    handleHeaderClick,
}) => {
    return (
        <>
            <HStack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    padding: '0px',
                    gap: '12px',
                    left: 'calc(50% - 343px/2)',
                    top: '31px',
                }}
            >
                {columns.map((item: any, index: any) => {
                    return (
                        <Box
                            as='button'
                            key={item.title}
                            sx={{
                                boxSizing: 'border-box',
                                padding: '12px 12px',
                                gap: '6px',
                                width: '100%',
                                background: '#FFFFFF',
                                borderRadius: '12px',
                                ...columnClass[index].isSelected,
                            }}
                            onClick={() => handleHeaderClick(item, index)}
                        >
                            <Text
                                variant={'body-md'}
                                color={'brand.700'}
                                whiteSpace={'pre-line'}
                            >
                                {item.title}
                            </Text>
                            <Text
                                color={
                                    'var(--Monochrome-900, var(--colour-text-body-monochrome-900, #4E4670));'
                                }
                                variant={'title-sm'}
                                whiteSpace={'pre-line'}
                            >
                                {item.price}
                            </Text>
                        </Box>
                    )
                })}
            </HStack>
        </>
    )
}
