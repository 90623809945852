import { Box, Stack } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { TickIcon } from '@irishlife/ilgroupdesignsystem.icons'
import React from 'react'
export interface BenefitsCardProps {
    benefits: string[]
    title: string
}

export const BenefitsCard: React.FC<BenefitsCardProps> = ({
    benefits,
    title,
}) => {
    return (
        <Box pt={'16px'}>
            <Card px={['24px', '24px']} py={['40px', '40px']}>
                <Stack>
                    <Text variant={'title-md'} pb={'40px'}>
                        {title}
                    </Text>
                    {benefits.map((benefit, index) => (
                        <Box display='flex' key={index}>
                            <TickIcon color='accent.700' fontSize={24} mr={6} />
                            <Text variant={'body-md'}>{benefit}</Text>
                        </Box>
                    ))}
                </Stack>
            </Card>
        </Box>
    )
}
