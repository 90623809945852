import {
    Box,
    FormLabel,
    InputGroup,
    InputRightElement,
    InputLeftElement,
    Flex,
} from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import {
    DetailsFillIcon,
    CheckIcon,
} from '@irishlife/ilgroupdesignsystem.icons'
import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { getStyles } from '../../../styles/getStyles'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'

export interface AdjustmentFormProps {
    onSubmit: (data: any) => void
    peopleCovered: string
    coverNeeded: string
    term: string
}

export const AdjustmentForm: React.FC<AdjustmentFormProps> = ({ ...props }) => {
    const { onSubmit, peopleCovered, coverNeeded, term } = props

    const { handleSubmit, control } = useForm()

    return (
        <Box my={'24px'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={'16px'}>
                    <Controller
                        name='coverNeeded'
                        control={control}
                        defaultValue=''
                        render={({ onChange, onBlur, value }) => (
                            <Box sx={getStyles({})}>
                                <FormLabel ml={'20px'}>
                                    <Text
                                        variant={'body-md'}
                                        color={'monochrome.800'}
                                        fontWeight={'semibold'}
                                    >
                                        Amount covered
                                    </Text>
                                </FormLabel>
                                <Box>
                                    <Flex gap={'6px'} alignItems={'center'}>
                                        <CheckIcon color={'#50C9B5'} />
                                        <InputGroup pos={'relative'}>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                fontSize='1em'
                                            >
                                                €
                                            </InputLeftElement>
                                            <NumberFormat
                                                id='termlife-input'
                                                thousandSeparator={true}
                                                onValueChange={({ value }) =>
                                                    onChange(value)
                                                }
                                                defaultValue={coverNeeded}
                                                name={'coverNeeded'}
                                            />

                                            <InputRightElement
                                                pointerEvents='none'
                                                fontSize='1.2em'
                                            >
                                                <DetailsFillIcon />
                                            </InputRightElement>
                                        </InputGroup>
                                    </Flex>
                                </Box>
                            </Box>
                        )}
                    />
                </Box>
                <Box mb={'16px'}>
                    <Controller
                        name='term'
                        control={control}
                        defaultValue=''
                        render={({ onChange, onBlur, value }) => (
                            <Box sx={getStyles({})}>
                                <FormLabel ml={'20px'}>
                                    <Text
                                        variant={'body-md'}
                                        color={'monochrome.800'}
                                        fontWeight={'semibold'}
                                    >
                                        Term (in years)
                                    </Text>
                                </FormLabel>
                                <Box pos={'relative'}>
                                    <Flex gap={'6px'} alignItems={'center'}>
                                        <CheckIcon color={'#50C9B5'} />
                                        <InputGroup>
                                            <NumberFormat
                                                id='currency'
                                                thousandSeparator={true}
                                                suffix=' years'
                                                onValueChange={({ value }) =>
                                                    onChange(value)
                                                }
                                                defaultValue={term}
                                                name={'term'}
                                            />
                                            <InputRightElement
                                                pointerEvents='none'
                                                fontSize='1.2em'
                                            >
                                                <DetailsFillIcon />
                                            </InputRightElement>
                                        </InputGroup>
                                    </Flex>
                                </Box>
                            </Box>
                        )}
                    />
                </Box>
                <Box mb={'24px'}>
                    <Flex gap={'18px'} alignItems={'center'}>
                        <CheckIcon color={'#50C9B5'} />
                        <Box>
                            <Text variant={'label-md'}>People covered</Text>
                            <Text
                                variant={'label-md'}
                                color={'var(--Black, #0C1E68)'}
                            >
                                {peopleCovered}
                            </Text>
                        </Box>
                    </Flex>
                </Box>
                <Button variant={'secondary'} type='submit'>
                    Update
                </Button>
            </form>
        </Box>
    )
}
