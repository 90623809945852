export const quoteCardData = {
    taxRelief: {
        id: 2,
        props: {
            label: `Tax Relief`,
            data: [
                ` Tax relief is not guaranteed. Rates are current as of January 2024.`,
                `To claim tax relief you can apply to your inspector of taxes to adjust your tax credits. Contributions deducted from salary will receive immediate tax relief. To be eligible to claim income tax relief, your income must be taxable either Schedule E or Schedule D (Case l or ll).`,
                ` If you are self-employed, you must include your pension contributions in your self-assessment tax returns in order to obtain income tax relief. Any employer contributions will receive tax relief in the year the contribution is made.`,
                `Pension income in retirement is subject to income tax at your highest rate on withdrawal, Universal Social Charge, PRSI (if applicable) and any other charges or levies (“tax”) applicable at this time.`,
            ],
        },
    },
    assumptions: {
        id: 1,
        props: {
            label: `Assumptions`,
            data: [
                `Pensions projection based on a retirement age of 66`,
                `Regular contributions go up by 3% each year over the term of your plan. Contributions are invested in a standard PRSA product, which has a fund management charge of 1.00% per annum and a contribution charge of up to 5% of each contribution.`,
                `The projected values assume an investment return before retirement of 4.86% per annum. This rate is for illustrative purposes only and is not guaranteed. Actual investment growth will depend on the performance of the underlying investments and may be more or less than illustrated.`,
                `An inflation rate of 2% per annum is used to express future values in today's terms.`,
                `The estimated annuities quoted are payable monthly in advance. The guaranteed period is 5 years, so in the event of early death during these five years, the income will continue to be paid for the balance of this period.`,
                `Annuity payments increase by 2% per annum.`,
                `The annuity rate at your retirement date will depend on long-term interest rates and life expectancy assumptions at that time and is likely to be different from the annuity rate used in the illustration. Different annuity options can be chosen at retirement.`,
                `Under the Finance Act 2012, the age at which people qualify for the state pension is 66 years of age.`,
                `The state pension (Contributory) for a single person is €14,470 per annum as of January 2024.`,
            ],
        },
    },
}
