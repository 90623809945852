import React, { FC } from 'react'
import { MultiCampaignFeature } from 'common/components/organisms/MultiCampaignFeature/index'

const MultiCampaignFeatureWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)
    return <MultiCampaignFeature {...processedProps} />
}
export { MultiCampaignFeatureWrapper as MultiCampaignFeature }

const getProps = (props: any) => {
    const { title, items, image_left } = props
    
    return {
        title,
        cards: items.map((item: any) => ({
            title: item.title,
            subtitle: item.subtitle,
            subtitle2: item.subtitle2,
            paragraph: item.description_b57cf5a || item.description,
            imageSrc: item.file,
            imageLeft: image_left,
            callToAction: {
                label: item.button_c9ad60e__text,
                url: item.button_c9ad60e__url,
                variant: item.card_cta_0396ba7__cta_variant,
            },
        })),
    }
}
