import { Box } from '@chakra-ui/react'
import { InvestmentCalculator } from '@irishlifedigitalhub/ds.organisms.investment-calculator'
import { navigate } from '@reach/router'
import React from 'react'
import TagManager from 'react-gtm-module'

export const InvestmentCalculatorSection: React.FC = props => {
    const ctaConfig = getCtaConfig(props)

    return (
        <Box bg={'#fff'} p={[4, 12]}>
            <InvestmentCalculator onBook={() => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'secondaryButtonClick',
                        clickURL: ctaConfig.bookingUrl,
                        clickID: ctaConfig.bookingAnalyticsId
                    }
                })
                if(ctaConfig.bookingUrl.charAt(0) === '#') {
                    let element = document.getElementById(ctaConfig.bookingUrl.slice(1, ctaConfig.bookingUrl.length))
                    element?.scrollIntoView({ behavior: "smooth"})
                } else {
                    navigate(ctaConfig.bookingUrl)
                }
            }} onCalculate={() => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'secondaryButtonClick',
                        clickID: ctaConfig.calculateAnalyticsId
                    }
                })
            }} />
        </Box>
    )
}

const getCtaConfig = (data: any) => {
    return {
        bookingUrl: data.url,
        bookingAnalyticsId: data.booking_cta_analytics_id,
        calculateAnalyticsId: data.calculate_cta_analytics_id
    }
}

