import { API_URL } from 'core'
import { APIrequest } from './APIrequests'
const statePension = (14470 / 12).toFixed(2)
const pensionAPI: string = `${API_URL}/myonlineservices/servlet/submitPensionQuote`

export const user = {
    startPensionAge: 30,
    income: 30000,
    salaryContribution: 500,
    employerContribution: 230,
    currentPensionValue: 0,
    statePension: 500,
    yearlyPaymentTargetValue: 20000,
    retirementAge: 66,
}
export interface API {
    type5year: string
}
export const getQuote = (
    annualPensionValue: any,
    statePensionValue: number
) => {
    const userContributedPensionProjection = Number(annualPensionValue)

    const quote: Quote = {
        projection: userContributedPensionProjection + statePensionValue,
        barChart: [
            {
                userContributedPensionProjection,
                statePensionValue,
                userContributedPensionProjectionChartVal:
                    Number(
                        (
                            userContributedPensionProjection /
                            (userContributedPensionProjection +
                                statePensionValue)
                        ).toFixed(2)
                    ) * 100,
                statePensionValueChartVal:
                    Number(
                        (
                            statePensionValue /
                            (userContributedPensionProjection +
                                statePensionValue)
                        ).toFixed(2)
                    ) * 100,
            },
        ],
    }
    return quote
}
export interface Quote {
    projection: number
    barChart: [
        {
            statePensionValue: number
            userContributedPensionProjection: number
            userContributedPensionProjectionChartVal: number
            statePensionValueChartVal: number
        }
    ]
}
export const setScreen = (data: any) => {
    if (data.currentPension && data.currentPension === 'Y') {
        return 1
    }
    if (!data.currentPension || data.currentPension === 'N') {
        return 2
    }
}
const APItype5 = (
    age: number,
    income: number,
    contributionValue: number,
    currentPension: number,
    retirementAge: number
) => {
    return (
        'type=5&age=' +
        age +
        '&income=' +
        income +
        '&ret=' +
        retirementAge +
        '&ap=' +
        contributionValue +
        '&sp=' +
        (currentPension >= 0 ? currentPension : 0)
    )
}
const getAPIparamaters = (
    age: number,
    income: number,
    contributionValue: number,
    currentPensionValue: number,
    retirementAge: number
) => {
    return APItype5(
        age,
        income,
        contributionValue,
        currentPensionValue,
        retirementAge
    )
}

export const calculateTotalContribution = (data: any) => {
    if (data.contribution1) {
        if (data.jobContribution) {
            return Number(data.contribution1) + Number(data.jobContribution)
        } else {
            return Number(data.contribution1)
        }
    } else if (data.jobContribution) {
        return Number(data.contribution2) + Number(data.jobContribution)
    } else {
        return Number(data.contribution2)
    }
}

export const onSubmit = (data: any): Promise<any> => {
    const totalContribution = calculateTotalContribution(data)
    return APIrequest(
        pensionAPI,
        getAPIparamaters(
            Number(data.age),
            Number(data.salary),
            totalContribution, // contribution = contributionValue, jobContribution = employerContribution
            Number(data.pensionValue), // currentPensionValue
            data.retirementAge
        )
    ).then((response: any) => {
        if (response.quoteData !== undefined) {
            const monthResponse = getQuote(
                Number(
                    (Number(response.quoteData.annualPension) / 12).toFixed(2)
                ),
                // statePension,
                data.isStatePension === 'Y' ? Number(statePension) : 0
            )
            return monthResponse
        }
    })
}

export const pensionsTool = {
    setScreen: (data: any) => setScreen(data),
    onSubmit: (data: any) => onSubmit(data),
    stepsTransformation: (steps: any, data: any) => {
        const firstSteps = currentPensionTransformation(steps, data)
        const secondSteps = employmentStatusTransformation(firstSteps, data)
        const thirdSteps = employeeJobContributionTransformation(
            secondSteps,
            data
        )
        return thirdSteps
    },
}

export const employeeJobContributionTransformation = (
    steps: any,
    data: any
) => {
    let transformedSteps = steps
    if (!data.pensionThroughWork || data.pensionThroughWork === 'N') {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter(
                (step: any) =>
                    step.codename !== 'jobcontribution_pensiongeneric'
            ),
        }))
    }
    return transformedSteps
}

export const currentPensionTransformation = (steps: any, data: any) => {
    let transformedSteps = steps
    if (!data.currentPension || data.currentPension === 'N') {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter(
                (step: any) =>
                    step.codename !==
                        'pension_with_irishlife___pensionsgeneric' &&
                    step.codename !== 'pension_value___pension' &&
                    step.codename !== 'contribution2___pension' &&
                    step.codename !== 'pensionthroughwork___pension' &&
                    step.codename !== 'jobcontribution_pensiongeneric' &&
                    step.codename !== 'age___pensiongeneric' &&
                    step.codename !== 'salary___pensiongeneric'
            ),
        }))
    }
    if (data.currentPension && data.currentPension === 'Y') {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter(
                (step: any) =>
                    step.codename !== 'contribution1__pension' &&
                    step.codename !== 'age___pensiongeneric__copy_' &&
                    step.codename !== 'salary___pensiongeneric__copy_'
            ),
        }))
    }
    transformedSteps = transformedSteps.filter(
        (item: any) => item.steps.length > 0
    )
    return transformedSteps
}

export const employmentStatusTransformation = (steps: any, data: any) => {
    let transformedSteps = steps
    if (!data.employmentStatus || data.employmentStatus !== 'Employed') {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter(
                (step: any) => step.codename !== 'pensionthroughwork___pension'
            ),
        }))
    }
    return transformedSteps
}
