import { Box, Image, SimpleGrid, VStack } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { navigate } from '@reach/router'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
interface TrapeziumBannerProps {
    image?: string
    title?: string
    subtitle?: string
    callToAction?: any
}

export const TrapeziumBanner: React.FC<TrapeziumBannerProps> = (props) => {
    const { image, title, subtitle, callToAction } = props

    const isMobile = !useDesktop()

    return (
        <Box bgColor='F7F8FB' pb={['30px', '30px', 0]}>
            <SimpleGrid columns={{ sm: 1, md: 2 }}>
                <Box>
                    <Card
                        w='100%'
                        h='100%'
                        maxHeight='400px'
                        clipPath={
                            isMobile
                                ? ''
                                : 'polygon(0 0, 91% 0, 100% 100%, 0% 100%)'
                        }
                        style={{ padding: 0, borderRadius: '0px' }}
                    >
                        <Image
                            src={image}
                            alt='Sample Image'
                            height='100%'
                            width='100%'
                            maxHeight='400px'
                        />
                    </Card>
                </Box>
                <Box my='auto' ml={[0, 0, '10%']}>
                    <VStack
                        spacing={6}
                        justifyContent='center'
                        alignItems='flex-start'
                        m={'auto 0'}
                        maxWidth='460px'
                    >
                        <Text
                            color='#615A80'
                            fontWeight='normal'
                            variant='title-md'
                        >
                            {title}
                        </Text>
                        <Text variant='body-md'>{subtitle}</Text>
                        <Button
                            borderRadius='100px'
                            fontSize='18px'
                            fontWeight={600}
                            {...props.callToAction}
                            onClick={() => navigate(callToAction.url)}
                        >
                            {callToAction.label}
                        </Button>
                    </VStack>
                </Box>
            </SimpleGrid>
        </Box>
    )
}
