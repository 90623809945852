import React, { FC } from 'react'
import DocumentDownloadPanel from 'common/components/organisms/DocumentDownloadPanel/DocumentDownloadPanel'

const DocumentDownloadPanelWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <DocumentDownloadPanel {...processedProps} />
}
export { DocumentDownloadPanelWrapper as DocumentDownloadPanel }
const getProps = (data: any) => {
    return {
        title: data.title,
        cards: data?.documents?.map((item: any) => {
            return {
                title: item.title,
                fileSize: '1.2MB',
                downloadPath: item.file,
            }
        }),
    }
}
