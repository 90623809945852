import {
    Pillars as PillarsOrganism,
    PillarsProps as PillarsOrganismProps,
} from 'common/components/organisms/Pillars'
import React from 'react'

export interface PillarsProps extends PillarsOrganismProps {}

export const Pillars: React.FC<PillarsProps> = (props) => {
    return (
        <>
            <PillarsOrganism {...getPillarProps(props)} />
        </>
    )
}

export const getPillarProps = (props: any): PillarsProps => {
    return {
        title: props.title,
        subtitle: props.subtitle,
        title___color: props.title___color,
        subtitle___color: props.subtitle___color,
        pillars: [
            {
                text: props.life_insurance_text,
                variant: 'life',
                primaryCta: {
                    label: props.life_insurance_ctas[0].cta__label,
                    url: props.life_insurance_ctas[0].cta__url,
                    analyticsId: props.life_insurance_ctas[0].cta__analytics_id,
                    hide_cta: props.hide_primary_ctas
                },
                secondaryCta: {
                    label: props.life_insurance_ctas?.[1].cta__label,
                    url: props.life_insurance_ctas?.[1].cta__url,
                    analyticsId:
                        props.life_insurance_ctas?.[1].cta__analytics_id,
                },
            },
            {
                text: props.health_insurance_text,
                variant: 'health',
                primaryCta: {
                    label: props.health_insurance_ctas[0].cta__label,
                    url: props.health_insurance_ctas[0].cta__url,
                    analyticsId:
                        props.health_insurance_ctas[0].cta__analytics_id,
                    hide_cta: props.hide_primary_ctas
                },
                secondaryCta: {
                    label: props.health_insurance_ctas?.[1].cta__label,
                    url: props.health_insurance_ctas?.[1].cta__url,
                    analyticsId:
                        props.health_insurance_ctas?.[1].cta__analytics_id,
                },
            },
            {
                text: props.pensions_text,
                variant: 'pensions',
                primaryCta: {
                    label: props.pensions_ctas[0].cta__label,
                    url: props.pensions_ctas[0].cta__url,
                    analyticsId: props.pensions_ctas[0].cta__analytics_id,
                    hide_cta: props.hide_primary_ctas
                },
                secondaryCta: {
                    label: props.pensions_ctas?.[1].cta__label,
                    url: props.pensions_ctas?.[1].cta__url,
                    analyticsId: props.pensions_ctas?.[1].cta__analytics_id,
                },
            },
            {
                text: props.investments_text,
                variant: 'investments',
                primaryCta: {
                    label: props.investments_ctas[0].cta__label,
                    url: props.investments_ctas[0].cta__url,
                    analyticsId: props.investments_ctas[0].cta__analytics_id,
                    hide_cta: props.hide_primary_ctas
                },
                secondaryCta: {
                    label: props.investments_ctas?.[1].cta__label,
                    url: props.investments_ctas?.[1].cta__url,
                    analyticsId: props.investments_ctas?.[1].cta__analytics_id,
                },
            },
        ],
    }
}
