import React from 'react'
import {
    TimelineSteps,
    TimelineStepsProps
} from 'common/components/organisms/TimelineSteps'
export interface TimelineProps extends TimelineStepsProps {}

export const Timeline: React.FC<TimelineProps> = props => {
    const data = getTimelineData(props)
    return <TimelineSteps {...data} />
}

export const getTimelineData = (data: any): TimelineProps => {
    return {
        text: data.text,
        title: data.title,
        heading: data.heading,
        steps: data.timeline__steps
    }
}
