import React from 'react'
import { useDesktop } from 'common/hooks/useDesktop'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import {
    ProductListCard,
    ProductListCardProps,
} from 'common/components/molecules/ProductListCard'
import { Grid, GridItem } from '@chakra-ui/react'
import { RichText } from 'common/components/molecules/RichText'
export interface ProductRowListsProps {
    productRowLists?: ProductListCardProps[]
    text: string
    title: string
    subTitle?: string
}
export const ProductRowLists: React.FC<any> = ({
    title,
    subTitle,
    text,
    productRowLists,
}) => {
    const desktop = useDesktop()
    return (
        <section>
            <Text
                color={'#2B827B'}
                variant={'caption-xl'}
                paddingBottom={'32px'}
            >
                {title}
            </Text>
            <Grid
                templateRows={'repeat(1, 1fr)'}
                templateColumns={desktop ? 'repeat(6, 1fr)' : 'repeat(1, 1fr)'}
                gap={2}
            >
                <GridItem
                    colSpan={desktop ? 2 : undefined}
                    paddingRight={desktop ? '70px' : undefined}
                    paddingBottom={!desktop ? '16px' : undefined}
                >
                    {subTitle && (
                        <Text
                            variant={'title-xl'}
                            paddingBottom={'32px'}
                            color={'#3A3160'}
                        >
                            {subTitle}
                        </Text>
                    )}
                    <RichText
                        color='textSecondary'
                        html
                        label={text}
                        variant='body2'
                    />
                </GridItem>
                <GridItem
                    colSpan={desktop ? 4 : undefined}
                    colStart={desktop ? 3 : undefined}
                >
                    <Grid templateColumns='repeat(1, 1fr)' gap={4}>
                        {productRowLists.map((productRowList: any) => (
                            <GridItem key={productRowList.title}>
                                <ProductListCard
                                    {...productRowList}
                                    productListing={true}
                                />
                            </GridItem>
                        ))}
                    </Grid>
                </GridItem>
            </Grid>
        </section>
    )
}
