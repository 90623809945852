import React from 'react'
import { ChevronRightIcon } from '@irishlife/ilgroupdesignsystem.icons'
import TagManager from 'react-gtm-module'
import { Link } from 'common/components/atoms/Link'
import { Box } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'

export interface ProductListingCTAProps {
    label: string
    variant?: string
    url: string
    onClick?: () => void
    ishighlighted: boolean
}

export const ProductListingCTA: React.FC<ProductListingCTAProps> = ({
    label = 'Learn more',
    variant = 'ghost',
    onClick,
    url = '',
    ishighlighted,
}) => {
    return (
        <Link
            data-testid='CardCta'
            onClick={() => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'secondaryButtonClick',
                        clickText: label,
                        clickURL: url,
                        clickID: label,
                    },
                })
                if (onClick) {
                    onClick()
                }
            }}
            path={url}
        >
            <Box
                color={ishighlighted ? '#fff' : 'monochrome.800'}
                display={'flex'}
                justifyContent={'flex-end'}
                marginTop={11}
            >
                <Button
                    color={ishighlighted ? '#fff' : 'vibrant.700'}
                    rightIcon={label ? <ChevronRightIcon /> : undefined}
                    size='md'
                    variant='ghost'
                >
                    {' '}
                    {variant === 'download' ? 'Download PDF' : label}
                </Button>
            </Box>
        </Link>
    )
}
