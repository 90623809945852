export const getStepSize = (contribution: number) => {
    if (contribution < 500) {
        return 50 // Step size for contributions between €201 and €500
    } else if (contribution < 1000) {
        return 100 // Step size for contributions between €501 and €1000
    } else if (contribution < 2500) {
        return 250 // Step size for contributions between €1001 and €2500
    } else if (contribution < 5000) {
        return 500 // Step size for contributions between €2501 and €5000
    } else if (contribution < 10000) {
        return 1000 // Step size for contributions between €5001 and €10000
    } else {
        return 2000 // Step size for contributions above €10000
    }
}

export const getNextContribution = (
    current: number,
    increase: boolean
): number => {
    // Determine the step size based on the current contribution
    const stepSize = getStepSize(current)

    if (increase) {
        // Calculate next higher threshold by rounding up to the nearest step size
        return Math.ceil((current + 1) / stepSize) * stepSize
    } else {
        // Calculate next lower threshold by rounding down to the nearest step size
        return Math.floor((current - 1) / stepSize) * stepSize
    }
}
