import Axios from 'axios'
import qs from 'qs'

export const APIrequestOnePlan = async (URL: string, params: any) => {
    const { paramsStandard, paramsEssential, paramsComprehensive } = params
    const [standard, essential, comprehensive] = await Promise.all([
        Axios.get(`${URL}?${paramsStandard}`),
        Axios.get(`${URL}?${paramsEssential}`),
        Axios.get(`${URL}?${paramsComprehensive}`),
    ])

    let standardQuoteData
    let essentialQuoteData
    let comprehensiveQuoteData
    let data
    if (
        standard.data === 'Error' ||
        essential.data === 'Error' ||
        comprehensive.data === 'Error'
    ) {
        data = { error: true }
    } else {
        standardQuoteData = qs.parse(
            standard.data
                .replace('var result = ', '')
                .replace(';', '')
                .replaceAll("'", '')
        )
        essentialQuoteData = qs.parse(
            essential.data
                .replace('var result = ', '')
                .replace(';', '')
                .replaceAll("'", '')
        )
        comprehensiveQuoteData = qs.parse(
            comprehensive.data
                .replace('var result = ', '')
                .replace(';', '')
                .replaceAll("'", '')
        )
        data = {
            standardQuoteData,
            essentialQuoteData,
            comprehensiveQuoteData,
            error: false,
        }
    }
    return data
}
export const APIrequest = async (URL: string, paramaters: string) => {
    const request = await Axios.get(`${URL}?${paramaters}`)

    let quoteData
    let data
    if (request.data === 'Error') {
        data = { error: true }
    } else {
        quoteData = qs.parse(
            request.data
                .replace('var result = ', '')
                .replace(';', '')
                .replaceAll("'", '')
        )
        data = {
            quoteData,
            error: false,
        }
    }
    return data
}
