import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import React from 'react'
import Loading from '../../../Loading'
import { AdjustmentControls } from './components/AdjustmentControls'
import { IncomeProjectionText } from './components/IncomeProjectionText'
import { MonthlyIncomeDisplay } from './components/MonthlyIncomeDisplay'
import { RetirementBarChart } from './components/RetirementBarChart'
import { getNextContribution } from './utils/getNextContribution'
import { usePensionCalculator } from './utils/usePensionCalculator'

export interface PensionCalculatorOutputProps {}

export const PensionCalculatorOutput: React.FC<PensionCalculatorOutputProps> =
    () => {
        const { state } = useCalculatorMachine()
        const isRefetching = state.matches('Refetching Quote Details')

        const {
            state: { userContribution, retirementAge },
            actions: { setContribution, setRetirementAge },
        } = usePensionCalculator()

        return (
            <>
                <Card>
                    <Text variant={'title-sm'} pb='12px'>
                        Monthly retirement income
                    </Text>
                    {isRefetching ? (
                        <Loading value={'pension'} />
                    ) : (
                        <>
                            <MonthlyIncomeDisplay />
                            <RetirementBarChart />
                        </>
                    )}
                    <AdjustmentControls
                        isDisabled={isRefetching}
                        label='Adjust retirement monthly payments'
                        value={userContribution}
                        min={1}
                        onDecrease={() =>
                            setContribution(
                                getNextContribution(userContribution, false)
                            )
                        }
                        onIncrease={() =>
                            setContribution(
                                getNextContribution(userContribution, true)
                            )
                        }
                    />
                    <AdjustmentControls
                        isDisabled={isRefetching}
                        label='Adjust retirement age'
                        min={60}
                        value={retirementAge}
                        onDecrease={() => setRetirementAge(retirementAge - 1)}
                        onIncrease={() => setRetirementAge(retirementAge + 1)}
                    />
                    <IncomeProjectionText />
                </Card>
            </>
        )
    }
