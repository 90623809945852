import React from 'react'
import { ProofPoints, ProofPointsProps } from '../../ProofPoints'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProofPointsSectionProps extends ProofPointsProps {}

export const ProofPointsSection: React.FC<ProofPointsSectionProps> = props => {
    return <ProofPoints {...getProofPointsData(props)} />
}

const getProofPointsData = (data: any): ProofPointsSectionProps => {
    return {
        heading: data.proof_points__heading,
        variant: data.proof_points__variant,
        cards: data.proof_points__items.map((item: any) => ({
            title: item.title,
            icon: item.icon,
            text: item.text,
            callToAction: {
                label: item.card_cta_0396ba7__cta_label,
                url: item.card_cta_0396ba7__cta_url
            }
        }))
    }
}
