import { Box } from '@chakra-ui/react'
import React from 'react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { ProductListingCTA, ProductListingCTAProps } from './ProductListingCTA'
export interface ProductListCardProps {
    ishighlighted?: boolean
    callToAction?: ProductListingCTAProps
    icon: string
    text: string
    title: string
    iconSize?: 'md' | 'lg'
    subtitle?: string
}
const iconSizeList = {
    desktop: {
        md: 40,
        lg: 64,
    },
}
export const ProductListCard: React.FC<ProductListCardProps> = ({
    ishighlighted = false,
    icon,
    text,
    title,
    iconSize = 'md',
    subtitle,
    callToAction,
}) => {
    return (
        <>
            <Box
                bg={ishighlighted ? 'brand.700' : '#fff'}
                borderRadius={'16px'}
                display={'flex'}
                flexDirection={'column'}
                color={ishighlighted ? '#fff' : '#5261AC'}
                px={9}
                py={'24px'}
            >
                {icon && (
                    <img
                        alt='ProductListCard Icon'
                        src={icon}
                        height={iconSizeList['desktop'][iconSize]}
                        width={iconSizeList['desktop'][iconSize]}
                    />
                )}
                <Text
                    variant='title-sm'
                    marginTop={7}
                    marginBottom={!subtitle ? 5 : 1}
                    color={ishighlighted ? '#fff' : 'brand.800'}
                >
                    {title}
                </Text>
                {subtitle && (
                    <Text
                        variant='title-xs'
                        marginBottom={5}
                        color={ishighlighted ? '#fff' : 'monochrome.800'}
                    >
                        {subtitle}
                    </Text>
                )}
                <Text
                    as={'html'}
                    variant={'body-lg'}
                    color={ishighlighted ? '#fff' : 'monochrome.800'}
                >
                    {text}
                </Text>
                {callToAction && callToAction.label && (
                    <ProductListingCTA
                        {...callToAction}
                        ishighlighted={ishighlighted}
                    />
                )}
            </Box>
        </>
    )
}
