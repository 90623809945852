import { RouteComponentProps } from '@reach/router'
import { Wrapper } from 'common/components/molecules/Wrapper'
import SupportCenter from 'common/components/organisms/KnowledgeBase/SupportCenter'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SupportCenterBase extends RouteComponentProps {
    pageContext: any
}

export const SupportCenterBase: React.FC<SupportCenterBase> = () => {
    return (
        <Wrapper>
            <SupportCenter />
        </Wrapper>
    )
}
