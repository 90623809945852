import { Banner, BannerProps } from 'common/components/molecules/Banner'
import React from 'react'

export interface BannerCtaProps extends BannerProps {}

export const BannerCta: React.FC<BannerCtaProps> = props => {
    return <Banner {...getBannerCtaData(props)} />
}

const getBannerCtaData = (data: any): BannerProps => {
    return {
        title: data.title,
        subtitle: data.subtitle,
        text: data.text,
        callToAction: {
            analyticsId: data?.card_cta_0396ba7__cta_analytics_id,
            label: data?.card_cta_0396ba7__cta_label,
            url: data?.card_cta_0396ba7__cta_url || ''
        }
    }
}
