import React from 'react'
import { ComparisonTable } from '@irishlife/ilgroupdesignsystem.table'
export const Table: React.FC<any> = (props) => {
    const data = getTableData(props)
    return <ComparisonTable {...data} />
}
export type TableProps = {
    title?: string
    columns: ColumnProps[]
    rowgroup?: RowGroupProps[]
    rows?: RowProps[]
}

export type ColumnProps = {
    columnTitle?: string
    columnSubtitle?: string
    columnCTALabel?: string
    url?: string
    columnCTAVariant?: string
    cellValues: CellProps[]
}

export type CellProps = {
    cellValue: string
}

export type RowGroupProps = {
    rowGroupTitle?: string
    isExpanded?: boolean
    Collapsible?: boolean
    rows?: RowProps[]
}
export type RowProps = {
    rowTitle?: string
}

export const getTableData = (data: any): any => {
    return {
        title: data.title ? data.title : '',
        columns: getTableColumns(data.table_columns),
        rowgroup: getTableRowGroup(data.table_rows),
    }
}

export const getTableRowGroup = (rowGroup: any) => {
    return rowGroup.map((rowGroupItem: any) => {
        const rowValues = rowGroupItem.table_rows.map((row: any) => {
            return {
                rowTitle: row.row_title,
            }
        })
        return {
            rowGroupTitle: rowGroupItem.rowgroup_title,
            Collapsible: rowGroupItem.is_collapsible === 'Yes' ? true : false,
            isExpanded: rowGroupItem.is_expanded === 'Yes' ? true : false,
            rows: rowValues,
        }
    })
}

export const getTableColumns = (columns: any) => {
    return columns.map((column: any) => {
        const cellValues = column.column_cell_values.map((cellValue: any) => {
            return {
                cellValue: cellValue.column_boolean_values
                    ? cellValue.column_boolean_values
                    : cellValue.column_text_values,
            }
        })
        return {
            columnTitle: column.column_title,
            columnSubtitle: column.column_subtitle,
            columnCTALabel: column.call_to_action__label,
            url: column.call_to_action__url,
            columnCTAVariant: column.call_to_action__variant,
            cellValues: cellValues,
        }
    })
}
