import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { debounce } from 'lodash'
import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import {
    CalculatorMachineEvent,
    IncomeProtectionFormType,
} from 'common/components/organisms/Calculator/utils/interfaces'

export const incomeProtectionCalculatorState = atom({
    key: 'incomeProtectionCalculatorState',
    default: {
        income: 0,
        amountToProtect: 0,
        premium: 0,
        coveredIncome: 0,
        stateCover: 12064,
    },
})

export function useIncomeProtectionCalculatorCalculator() {
    const [calcState, setCalcState] = useRecoilState(
        incomeProtectionCalculatorState
    )
    const { state, send } = useCalculatorMachine()

    useEffect(() => {
        if (state.context?.formValues) {
            const income = Number(
                (state.context.formValues as IncomeProtectionFormType).income ||
                    0
            )

            setCalcState({
                income,
                amountToProtect: Number(
                    (state.context.formValues as IncomeProtectionFormType)
                        .amountToProtect || 0
                ),
                premium: Number(
                    (
                        state.context as {
                            quoteData?: { quoteData?: { prem?: number } }
                        }
                    ).quoteData?.quoteData?.prem ?? 0
                ),
                coveredIncome: Number(
                    (income *
                        Number(
                            (
                                state.context
                                    .formValues as IncomeProtectionFormType
                            ).amountToProtect || 0
                        )) /
                        100 -
                        12064
                ),
                stateCover: calcState.stateCover,
            })
        }
    }, [state.context, setCalcState, calcState.stateCover])

    const debouncedUpdate = useCallback(
        debounce((income, amountToProtect) => {
            let user = {}
            user = {
                ...state?.context?.formValues,
                income: income,
                amountToProtect: amountToProtect,
            }
            send({
                type: 'Get Adjusted Quote',
                payload: { user },
            } as CalculatorMachineEvent)
        }, 1500),
        []
    )

    const changeUserContribution = useCallback(
        (income, amountToProtect) => {
            debouncedUpdate(income, amountToProtect)
        },
        [debouncedUpdate]
    )

    const setYearlyIncome = useCallback(
        (income) => {
            setCalcState((oldState) => ({
                ...oldState,
                income: income,
            }))
            changeUserContribution(income, calcState.amountToProtect)
        },
        [setCalcState, calcState.amountToProtect, changeUserContribution]
    )

    const setAmountToProtect = useCallback(
        (amountToProtect) => {
            setCalcState((oldState) => ({
                ...oldState,
                amountToProtect: amountToProtect,
            }))
            changeUserContribution(calcState.income, amountToProtect)
        },
        [setCalcState, calcState.income, changeUserContribution]
    )

    return {
        state: calcState,
        actions: {
            setYearlyIncome,
            setAmountToProtect,
        },
    }
}
