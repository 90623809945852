import React from 'react'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { RichText } from 'common/components/molecules/RichText'

export interface HelperTextProps {
    fieldName?: string
    helperText?: string
    formValues?: any
    errors?: any
    isTouched?: boolean
}
export const HelperText: React.FC<HelperTextProps> = ({
    fieldName,
    helperText,
    formValues,
    errors,
    isTouched = false,
}) => {
    let dynamicHelperText = ''
    let amountPercentageToProtect = 0
    const { state } = useCalculatorMachine()
    if (
        state.context.calc_type === 'IncomeProtection' &&
        fieldName === 'amountToProtect'
    ) {
        amountPercentageToProtect =
            (+formValues?.income * +formValues?.amountToProtect) / 100
        dynamicHelperText = `${formValues?.amountToProtect}% = ${amountPercentageToProtect}`
    }
    return (
        <>
            {isTouched && errors[fieldName ?? ''] && (
                <p style={{ color: 'var(--chakra-colors-error)' }}>
                    {errors?.[fieldName ?? '']?.message}
                </p>
            )}
            {helperText && (!errors?.[fieldName ?? ''] || !isTouched) && (
                <RichText label={helperText} html></RichText>
            )}
            {dynamicHelperText &&
                !helperText &&
                amountPercentageToProtect > 0 && (
                    <RichText label={dynamicHelperText} html></RichText>
                )}
        </>
    )
}
