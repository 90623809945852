import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardsProps extends BenefitsProps {}

export const Cards: React.FC<CardsProps> = (props) => {
    return <Benefits {...getCardsData(props)} />
}

const getCardsData = (data: any): CardsProps => {
    return {
        heading: data.threecards__heading,
        title: data.threecards__title,
        subtitle: data.threecards__subtitle,
        benefits: data.threecards__cards.map((card: any) => ({
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url,
            },
            icon: card.icon,
            text: card.text,
            title: card.title,
            subtitle: card.subtitle,
        })),
        ctaLabel: data.threecards__cta_label,
        ctaUrl: data.threecards__cta_url,
        noWrap: data.threecards__wrap_cards === 'No',
        alignTextLeft: data.threecards__align_text_left === 'true',
    }
}
