import { Box } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Grid } from '@material-ui/core'
import React from 'react'

export interface LegendProps {
    label: string
    value: string
    color?: string
    fill?: string
}

export const Legend: React.FC<LegendProps> = ({
    label,
    color,
    value,
    fill,
}) => {
    return (
        <Grid item xs={12} md={4}>
            <Grid container style={{ marginTop: '15px' }}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box
                        bg={color}
                        bgImage={fill}
                        borderRadius={5}
                        bgRepeat={'repeat'}
                        h={'17px'}
                        w={'17px'}
                        mr={'10px'}
                    ></Box>
                    <Box>
                        <Text variant={'subtitle-md'} fontWeight={'semibold'}>
                            {value}
                        </Text>
                        <Text variant={'body-sm'}>{label} </Text>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}
