import {
    getResourcesData,
    Resources as ResourcesOrganism,
    ResourcesProps as ResourcesOrganismProps
} from 'common/components/organisms/Resources'
import React from 'react'

export interface ResourcesProps extends ResourcesOrganismProps {}

export const Resources: React.FC<ResourcesProps> = props => {
    const data = getResourcesData(props)
    return <ResourcesOrganism {...data} />
}
