import { APIrequest } from './APIrequests'
import qs from 'qs'
import { API_URL } from 'core'

// const API_URL = 'https://apps.irishlife.ie'
const API: string = `${API_URL}/myonlineservices/servlet/LifeCoverQuote`

export const mortgageProtectionTool = {
    onSubmit: (data: any) => onSubmit(data),
    stepsTransformation: (steps: any, data: any) => {
        const firstSteps = twoPeopleTransformation(steps, data)

        return firstSteps
    },
}

export const twoPeopleTransformation = (steps: any, data: any) => {
    let transformedSteps = steps
    if (data.coverFor === '1') {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter(
                (step: any) =>
                    step.codename !== 'age2___mp_calc' &&
                    step.codename !== 'smoker2___mp_calc'
            ),
        }))
    }
    return transformedSteps
}

export const onSubmit = (data: any): Promise<any> => {
    return APIrequest(API, getApiConstants('lifeMortgage', 38, data)).then(
        (response: any) => {
            if (response !== undefined) {
                return response
            }
        }
    )
}

export const getApiConstants: any = (
    quickQuoteId: string,
    productId: number,
    data: any
) => {
    return qs.stringify({
        quickQuoteId: quickQuoteId,
        productId: productId,
        coverTypeCd: 'L',
        indexation: 'FALSE',
        frequencyCd: 'M',
        conversion: 'FALSE',
        dateOfBirth1Day: 14,
        dateOfBirth1Month: 10,
        dateOfBirth2Day: 14,
        dateOfBirth2Month: 10,
        dateOfBirth1Year: new Date().getFullYear() - +data.age1, ///need to logic here
        dateOfBirth2Year: new Date().getFullYear() - +data.age2,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        lifeCoverAmt: data.coverNeeded,
        term: data.term,
        jointLife: data.coverFor === '2' ? 'TRUE' : 'FALSE',
        age1: data.age1,
        age2: data.age2 ?? undefined,
        smokerCd1: data.isSmoker === 'Y' ? 'Y' : 'N',
        smokerCd2: data.isSmoker2
            ? data.isSmoker2 === 'Y'
                ? 'Y'
                : 'N'
            : undefined,
    })
}
