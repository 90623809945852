import { MultiPaginatedCards } from 'common/components/organisms/MultiPaginatedCards/index'
import React, { FC } from 'react'

const MultiPaginatedCardsWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <MultiPaginatedCards {...processedProps} />
}
export { MultiPaginatedCardsWrapper as MultiPaginatedCards }
export const getProps = (data: any) => {
    return {
        title: data.title,
        subTitle: data.subtitle,
        items: data.untitled_linked_items.map((item: any) => ({
            cardTitle: item.title,
            cards: item.cards.map((card: any) => ({
                icon: card.icon,
                description: card.title,
            })),
        })),
        secondTitle: data.secondtitle,
        secondSubtitle: data.secondsubtitle,
    }
}
