import { MediaCard } from 'common/components/organisms/MediaCard/index'
import React, { FC } from 'react'

const MediaCardWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <MediaCard {...processedProps} />
}
export { MediaCardWrapper as MediaCard }
const getProps = (props: any) => {
    const { title, image } = props

    return {
        title,
        image,
    }
}
