import { Box } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React from 'react'
import { quoteCardData } from '../../../config'

export interface AssumptionsCardProps {}

export const AssumptionsCard: React.FC<AssumptionsCardProps> = () => {
    return (
        <Box pt={'12px'}>
            <Card px={'36px'}>
                <Text variant={'title-md'} pb={'16px'}>
                    {quoteCardData.assumptions.props.label}
                </Text>
                <Text variant={'body-md'} pb={'20px'}>
                    This calculation has been made using the following
                    assumptions:
                </Text>
                <ul>
                    {quoteCardData.assumptions.props.data.map((item) => (
                        <li key={item}>
                            <Text variant={'body-md'}>{item}</Text>
                        </li>
                    ))}
                </ul>
            </Card>
        </Box>
    )
}
