import { Flex, Box } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React from 'react'
import Charts from './components/charts'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { PhoneIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { AdjustmentControls } from '../../../PensionCalculatorQuote/components/PensionCalculatorOutput/components/AdjustmentControls'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { useIncomeProtectionCalculatorCalculator } from './utils/useIncomeProtectionCalculator'
import { getNextContribution } from '../../../PensionCalculatorQuote/components/PensionCalculatorOutput/utils/getNextContribution'
import { AssumptionsCard } from './components/AssumptionsCard'
import { YourDetailsCard } from './components/YourDetailsCard'
import { RichText } from 'common/components/molecules/RichText'
import { Loading } from 'common/components/organisms/Calculator/components/quoteComponents/Loading'
export interface IncomeProtectionCalculatorOutputProps {}

export const IncomeProtectionCalculatorOutput: React.FC<IncomeProtectionCalculatorOutputProps> =
    () => {
        const { state } = useCalculatorMachine()
        const isRefetching = state.matches('Refetching Quote Details')
        const {
            state: {
                income,
                amountToProtect,
                premium,
                coveredIncome,
                stateCover,
            },
            actions: { setYearlyIncome, setAmountToProtect },
        } = useIncomeProtectionCalculatorCalculator()
        return (
            <>
                <Card>
                    <Text variant={'title-sm'} mb={'12px'}>
                        {' '}
                        Your quote{' '}
                    </Text>
                    {isRefetching ? (
                        <Loading value={'premium'} />
                    ) : (
                        <>
                            <Flex mb={'28px'}>
                                <Text
                                    variant={'title-xl'}
                                    color={'var(--Brand-800, #1D2F7C)'}
                                >
                                    {`€ ${premium.toLocaleString()}`}{' '}
                                </Text>
                                <Text
                                    pt={'14px'}
                                    pl={'6px'}
                                    variant={'body-md'}
                                >
                                    /per month
                                </Text>
                            </Flex>
                            <Charts />
                        </>
                    )}

                    <Box mt={'28px'}>
                        <AdjustmentControls
                            isDisabled={isRefetching}
                            label='Adjust your yearly income'
                            value={income}
                            min={1}
                            onDecrease={() =>
                                setYearlyIncome(
                                    getNextContribution(income, false)
                                )
                            }
                            onIncrease={() =>
                                setYearlyIncome(
                                    getNextContribution(income, true)
                                )
                            }
                        />
                        <AdjustmentControls
                            isDisabled={isRefetching}
                            label='Amount of income to protect'
                            max={75}
                            min={1}
                            value={amountToProtect}
                            onDecrease={() =>
                                setAmountToProtect(amountToProtect - 1)
                            }
                            onIncrease={() =>
                                setAmountToProtect(amountToProtect + 1)
                            }
                        />
                        <RichText
                            label={`${amountToProtect}% = ${
                                coveredIncome + stateCover
                            }`}
                        ></RichText>
                    </Box>

                    <Box>
                        <Text variant={'title-xs'} mt={'28px'}>
                            Your annual income protection benefit is{' '}
                            <b>{`€${coveredIncome.toLocaleString()}`}</b>
                        </Text>
                        <Text my={'12px'} variant={'caption-lg'}>
                            What this means:
                        </Text>
                        <Text variant={'body-md'} mb={'32px'}>
                            {` To protect ${amountToProtect.toLocaleString()}% (€${(
                                coveredIncome + stateCover
                            ).toLocaleString()}) of your total income of
                            €${income.toLocaleString()} you need to insure €${coveredIncome.toLocaleString()}. The remaining
                            €${stateCover.toLocaleString()} is covered by the state.`}
                        </Text>
                        <a href='tel:+353(01)7041979'>
                            <Button
                                leftIcon={
                                    <PhoneIcon
                                        fontSize={'28px'}
                                        color={'#FFFFF'}
                                    />
                                }
                                variant='primary'
                                size='md'
                            >
                                Call us (01) 704 1979
                            </Button>
                        </a>
                        <Text variant={'body-md'} mt={'28px'}>
                            This indicative quote is for a Income Protection
                            policy provided by Irish Life Assurance plc and is
                            subject to further assessment of health status. This
                            assessment may result in an increase in the cost of
                            cover. Irish Life Assurance plc is regulated by the
                            Central Bank of Ireland.
                        </Text>
                    </Box>
                </Card>
                <AssumptionsCard />
                <YourDetailsCard
                    income={income}
                    amountToProtect={amountToProtect}
                />
            </>
        )
    }
