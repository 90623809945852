import { getInvestmentValue } from './get-investment-value'

export const getDataPoints = (
    principal: number,
    regular: number,
    rate: number,
    term: number
) => {
    const dataPoints = []
    const currentYear = new Date().getFullYear()
    for (let i = 1; i <= term; i++) {
        dataPoints.push({
            year: String(currentYear + i),
            value: getInvestmentValue(principal, regular, rate, i),
        })
    }
    return dataPoints
}
