import React from 'react'
import {
    Box,
    Flex,
    Divider,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { CheckIcon, CloseIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { RichText } from 'common/components/molecules/RichText'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { OnePlanFormType } from 'common/components/organisms/Calculator/utils/interfaces'
import AddCircleIcon from './../utils/AddCircle'
export interface CoverDetail {
    isApplicable: boolean
    title: string
    yourCover: string
    partnerCover: string
}

export interface QuoteCardProps {
    coverDetails: any
}

export const QuoteCard: React.FC<QuoteCardProps> = ({ coverDetails }) => {
    const { data, price, modalContent } = coverDetails
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef(null)
    const handleHelpButton = () => {
        onOpen()
    }
    const { state } = useCalculatorMachine()
    const formValues = state.context.formValues as OnePlanFormType
    return (
        <Box
            sx={{
                borderTop: '6px solid #6977DF',
                boxShadow: '0px 0px 20px rgba(82, 97, 172, 0.24)',
                borderRadius: '12px',
                background: '#FFFFFF',
                padding: '16px',
                my: '16px',
            }}
        >
            <Flex align='start' justify='flex-start' wrap='nowrap'>
                <Image
                    src={
                        'https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/75889f25-4895-4db6-b092-769040ea3057/Life%20Cover%2040px.svg'
                    }
                    boxSize={'16px'}
                    alt='calculator icon'
                    mt={'9px'}
                    marginRight='12px'
                />
                <Box>
                    <Text variant={'title-sm'} mb='6px'>
                        {data.heading}
                    </Text>
                </Box>
            </Flex>
            <Divider
                my={'12px'}
                orientation='horizontal'
                borderColor={'monochrome.100'}
                borderWidth='1px'
            />
            {data.items.map((coverDetail: any, index: any) => {
                if (coverDetail.isApplicable) {
                    return (
                        <React.Fragment key={index}>
                            <Flex
                                align='start'
                                justify='flex-start'
                                wrap='nowrap'
                            >
                                <CheckIcon color={'#50C9B5'} mr={'8px'} />
                                <Box>
                                    <Text
                                        variant={'subtitle-sm'}
                                        fontWeight={'semibold'}
                                        mb='6px'
                                    >
                                        {coverDetail.title}
                                    </Text>
                                    <Text
                                        variant={'subtitle-sm'}
                                        fontWeight={'semibold'}
                                        mb='6px'
                                    >
                                        <strong>You:</strong>{' '}
                                        {coverDetail.yourCover}
                                    </Text>
                                    {formValues.jointLife === 'Y' && (
                                        <Text
                                            variant={'subtitle-sm'}
                                            fontWeight={'semibold'}
                                            mb='6px'
                                        >
                                            <strong>Partner:</strong>{' '}
                                            {coverDetail.partnerCover}
                                        </Text>
                                    )}
                                </Box>
                            </Flex>
                            <Divider
                                my={'12px'}
                                orientation='horizontal'
                                borderColor={'monochrome.100'}
                                borderWidth='1px'
                            />
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment key={index}>
                            <Flex
                                align='start'
                                justify='flex-start'
                                wrap='nowrap'
                            >
                                <CloseIcon color={'#D9145B'} mr={'8px'} />
                                <Box>
                                    <Text
                                        variant={'subtitle-sm'}
                                        fontWeight={'semibold'}
                                        mb='6px'
                                    >
                                        {coverDetail.title}
                                    </Text>
                                    <Text variant={'body-md'} mb='6px'>
                                        {' '}
                                        Not applicable
                                    </Text>
                                </Box>
                            </Flex>
                            <Divider
                                my={'12px'}
                                orientation='horizontal'
                                borderColor={'monochrome.100'}
                                borderWidth='1px'
                            />
                        </React.Fragment>
                    )
                }
            })}
            <Flex align='start' justify='flex-start' wrap='nowrap' gap={'3rem'}>
                <>
                    <Button
                        size='sm'
                        variant='ghost'
                        px={'0'}
                        leftIcon={<AddCircleIcon />}
                        onClick={handleHelpButton}
                    >
                        See costs & benefits
                    </Button>
                    <Modal
                        finalFocusRef={finalRef}
                        isOpen={isOpen}
                        onClose={onClose}
                    >
                        <ModalOverlay />
                        <ModalContent p={'16px'} style={{ margin: '16px' }}>
                            <ModalCloseButton />
                            <ModalBody>
                                <RichText label={modalContent} html></RichText>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    colorScheme='blue'
                                    size='sm'
                                    width={'100%'}
                                    onClick={onClose}
                                >
                                    Close
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
                <Box>
                    <Text
                        color={'var(--colour-text-title-black, #0C1E68);'}
                        variant={'title-md'}
                        fontWeight={'bold'}
                    >
                        {price}
                    </Text>
                    <Text variant={'body-sm'}>Monthly premium</Text>
                </Box>
            </Flex>
        </Box>
    )
}
