import { Box, Container } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import {
    Notice,
    NoticeCompact,
    NoticeProps,
} from 'common/components/organisms/Notice'
import React from 'react'

export interface NoticesProps {
    notices: NoticeProps[]
    variant?: 'default' | 'compact'
    displaytitle?: 'true' | 'false'
}

export const Notices: React.FC<NoticesProps> = (props) => {
    const notices = getNotices(props)
    const variant = props.variant
    const displayTitle = props.displaytitle
    if (variant === 'compact') {
        return (
            <>
                {displayTitle === 'true' && (
                    <Text variant='title-xl'>Notices</Text>
                )}
                {notices?.length > 0 && (
                    <Container maxW='6xl' py={'32px'}>
                        {notices?.map((notice, index) => (
                            <NoticeCompact key={index} {...notice} />
                        ))}
                    </Container>
                )}
            </>
        )
    }
    return (
        <>
            {notices?.length > 0 && (
                <Box>
                    <Container maxW='6xl' py={[16, 16, 20]}>
                        {notices?.map((notice, index) => (
                            <Notice key={index} {...notice} />
                        ))}
                    </Container>
                </Box>
            )}
        </>
    )
}

const getNotices = (data: any): NoticeProps[] => {
    const notices = (data.notices || []).map((notice: any) => ({
        title: notice.title,
        text: notice.text,
        subTitle: notice.subTitle ? notice.subTitle : notice.subtitle,
        variant: notice.variant,
        noticeVariant: data.variant,
        callToAction: {
            label: notice.call_to_action__label,
            [`${notice.call_to_action__iconlocation}Icon`]:
                notice.call_to_action__icon,
            text: notice.call_to_action__text,
            title: notice.call_to_action__title,
            url: notice.call_to_action__url,
            variant: notice.call_to_action__variant,
        },
    }))
    return notices
}
