import { Box, useMediaQuery } from '@chakra-ui/react'
import React, { FC } from 'react'

interface CenteredTextProps {
    text: string
    background: string
}

export const CenteredText: FC<CenteredTextProps> = (props) => {
    const { text, background } = props
    const [isMobile] = useMediaQuery('(max-width: 720px)')

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            bgColor={background === 'Light' ? '#fff' : '#EBEDF7'}
        >
            <Box
                px={isMobile ? 0 : '36px'}
                textAlign='center'
                color='#3A3160'
                fontSize='40px'
                sx={{ wordWrap: 'break-word' }}
                w={'85%'}
            >
                {text}
            </Box>
        </Box>
    )
}
