import { ProgressBarStepperStepsType } from './interfaces'

export const stepperSteps: ProgressBarStepperStepsType = {
    Investment: [
        {
            title: 'Starting amount',
        },
        {
            title: 'About you',
        },
        {
            title: 'Calculation',
        },
    ],
    Pensions: [
        {
            title: 'Status',
        },
        {
            title: 'Calculate',
        },
        {
            title: 'About you',
        },
        {
            title: 'Pension',
        },
    ],
    IncomeProtection: [
        {
            title: 'Start',
        },
        {
            title: 'Status',
        },
        {
            title: 'About you',
        },
        {
            title: 'Your Quote',
        },
    ],
    TermLife: [
        {
            title: 'Cover',
        },
        {
            title: 'About you',
        },
        {
            title: 'Your Quote',
        },
    ],
    MortgageProtection: [
        {
            title: 'Cover',
        },
        {
            title: 'Applicants',
        },
        {
            title: 'About you',
        },
        {
            title: 'Your Quote',
        },
    ],
    OnePlanWithPartner: [
        {
            title: 'You',
        },
        {
            title: 'Cover',
        },
        {
            title: 'Partner',
        },
        {
            title: 'Details',
        },
        {
            title: 'Quote',
        },
    ],
    OnePlan: [
        {
            title: 'You',
        },
        {
            title: 'Cover',
        },
        {
            title: 'Details',
        },
        {
            title: 'Quote',
        },
    ],
}
