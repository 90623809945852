import { APIrequestOnePlan } from './APIrequests'
import qs from 'qs'
import { API_URL } from 'core'
import { getApiParamsOnePlan } from './../utils/onePlanConfig'
import { occupations } from 'common/components/design-system/organisms/CalculatorCms/steps/CalculatorStepDropdown/occupations'
// const API_URL = 'https://apps.irishlife.ie'
const API: string = `${API_URL}/myonlineservices/servlet/LifeCoverQuote`

export const onePlanProtectionTool = {
    onSubmit: (data: any) => onSubmit(data),
    stepsTransformation: (steps: any, data: any) => {
        const firstSteps = childNumTransformation(steps, data)
        const secondSteps = jointTransformation(firstSteps, data)
        const thirdSteps = mortgageOrRentTransformation(secondSteps, data)
        const fourthSteps = additionalCoverTransformation(thirdSteps, data)
        const fifthSteps = SICTransformation(fourthSteps, data)
        const sixthSteps = AdditionalLifeCoverTransformation(fifthSteps, data)
        return sixthSteps
    },
}
export const AdditionalLifeCoverTransformation = (steps: any, data: any) => {
    let transformedSteps = steps
    if (!data.isAdditionalLifeCover || data.isAdditionalLifeCover === 'N') {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter((step: any) => {
                return step.codename !== 'lifecoveramount___oneplan'
            }),
        }))
    }
    //partner
    if (!data.isAdditionalLifeCover2 || data.isAdditionalLifeCover2 === 'N') {
        transformedSteps = transformedSteps.map((item: any) => ({
            ...item,
            steps: item.steps.filter((step: any) => {
                return step.codename !== 'partnerlifecoveramount___oneplan'
            }),
        }))
    }
    return transformedSteps
}
export const SICTransformation = (steps: any, data: any) => {
    let transformedSteps = steps
    if (!data.isSpecifiedIllnessCover || data.isSpecifiedIllnessCover === 'N') {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter((step: any) => {
                return step.codename !== 'specifiedillnesscoveramount___oneplan'
            }),
        }))
    }
    //partner
    if (
        !data.isSpecifiedIllnessCover2 ||
        data.isSpecifiedIllnessCover2 === 'N'
    ) {
        transformedSteps = transformedSteps.map((item: any) => ({
            ...item,
            steps: item.steps.filter((step: any) => {
                return (
                    step.codename !==
                    'partnerspecifiedillnesscoveramount___oneplan'
                )
            }),
        }))
    }
    return transformedSteps
}
export const additionalCoverTransformation = (steps: any, data: any) => {
    let transformedSteps = steps
    if (
        (!data.otherCoverRentNeither && !data.isOtherCover) ||
        (data.isOtherCover && data.isOtherCover === 'N') ||
        (data.otherCoverRentNeither && data.otherCoverRentNeither === 'N')
    ) {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter((step: any) => {
                return (
                    step.codename !== 'lifecoveramount___oneplan' &&
                    step.codename !== 'incomeprotectionorbillcover___oneplan' &&
                    step.codename !== 'specifiedillnesscover___oneplan' &&
                    step.codename !== 'specifiedillnesscoveramount___oneplan' &&
                    step.codename !== 'additionallifecover___oneplan'
                )
            }),
        }))
    }
    //partner
    if (
        (!data.otherCoverRentNeither2 && !data.isOtherCover2) ||
        (data.isOtherCover2 && data.isOtherCover2 === 'N') ||
        (data.otherCoverRentNeither2 && data.otherCoverRentNeither2 === 'N')
    ) {
        transformedSteps = transformedSteps.map((item: any) => ({
            ...item,
            steps: item.steps.filter((step: any) => {
                return (
                    step.codename !== 'partnerlifecoveramount___oneplan' &&
                    step.codename !==
                        'partnerincomeprotectionorbillcover___oneplan' &&
                    step.codename !==
                        'partnerspecifiedillnesscover___oneplan' &&
                    step.codename !==
                        'partnerspecifiedillnesscoveramount___oneplan' &&
                    step.codename !== 'partneradditionallifecover___oneplan'
                )
            }),
        }))
    }
    return transformedSteps
}
export const jointTransformation = (steps: any, data: any) => {
    let transformedSteps = steps
    if (data.jointLife === 'N') {
        transformedSteps = steps.filter((step: any) => {
            return step.codeName !== 'step_parnter_details___oneplan'
        })
    }
    return transformedSteps
}
export const childNumTransformation = (steps: any, data: any) => {
    let transformedSteps = steps
    if (data.childNum === 0) {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter((step: any) => {
                return step.codename !== 'youngestchildage___one_plan'
            }),
        }))
    }
    return transformedSteps
}

export const mortgageOrRentTransformation = (steps: any, data: any) => {
    let transformedSteps = steps
    if (data.mortgageOrRent === 'Mortgage') {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter((step: any) => {
                return (
                    step.codename !== 'rentamount___oneplan' &&
                    step.codename !== 'addcoverrentneither___oneplan' &&
                    step.codename !== 'partneraddcoverrentneither___oneplan'
                )
            }),
        }))
    }
    if (data.mortgageOrRent === 'Rent' || data.mortgageOrRent === 'Neither') {
        transformedSteps = steps.map((item: any) => ({
            ...item,
            steps: item.steps.filter((step: any) => {
                return (
                    step.codename !== 'mortgagepayment___oneplan' &&
                    step.codename !== 'repaymentterm___oneplan' &&
                    step.codename !== 'mortgagecoverondeath___one_plan' &&
                    step.codename !== 'mortgagecoveronillness___oneplan' &&
                    step.codename !== 'othercover___oneplan' &&
                    step.codename !==
                        'partnermortgagecoverondeath___one_plan' &&
                    step.codename !==
                        'partnermortgagecoveronillness___oneplan' &&
                    step.codename !== 'partnerothercover___oneplan'
                )
            }),
        }))
        if (data.mortgageOrRent === 'Neither') {
            transformedSteps = transformedSteps.map((item: any) => ({
                ...item,
                steps: item.steps.filter((step: any) => {
                    return step.codename !== 'rentamount___oneplan'
                }),
            }))
        }
    }
    return transformedSteps
}

export const onSubmit = (data: any): Promise<any> => {
    const onePlanParamsStandard = getApiParamsOnePlan(
        data,
        'standard',
        occupations
    )
    const onePlanParamsEssential = getApiParamsOnePlan(
        data,
        'essential',
        occupations
    )
    const onePlanParamsComprehensive = getApiParamsOnePlan(
        data,
        'comprehensive',
        occupations
    )
    let apiConstants = getApiConstants('lifeOnePlan', 104)
    const paramsStandard = qs.stringify({
        ...apiConstants,
        ...onePlanParamsStandard,
    })
    const paramsEssential = qs.stringify({
        ...apiConstants,
        ...onePlanParamsEssential,
    })
    const paramsComprehensive = qs.stringify({
        ...apiConstants,
        ...onePlanParamsComprehensive,
    })

    const params = { paramsStandard, paramsEssential, paramsComprehensive }
    return APIrequestOnePlan(API, params).then((response: any) => {
        if (response !== undefined) {
            return response
        }
    })
}
export const getApiConstants: any = (
    quickQuoteId: string,
    productId: number
) => {
    return {
        quickQuoteId: quickQuoteId,
        productId: productId,
        coverTypeCd: 'L',
        indexation: 'FALSE',
        frequencyCd: 'M',
        conversion: 'FALSE',
        dateOfBirth1Day: new Date().getDate(),
        dateOfBirth1Month: new Date().getMonth() + 1,
        dateOfBirth2Day: new Date().getDate(),
        dateOfBirth2Month: new Date().getMonth() + 1,
    }
}
