import React from 'react'
import { InvestmentCalculatorOutput } from './components/InvestmentCalculatorOutput'
import { Warnings } from './components/Warnings'
import { AdjustmentForm } from './components/AdjustmentForm'

import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'

export interface InvestmentCalcQuoteProps {
    props?: any
}
export const InvestmentCalcQuote: React.FC<InvestmentCalcQuoteProps> = () => {
    return (
        <>
            <Card mt={'16px'}>
                <InvestmentCalculatorOutput />
                <AdjustmentForm />
            </Card>

            <Warnings />
        </>
    )
}
