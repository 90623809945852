import { Box } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import numeral from 'numeral'
import React from 'react'
import { usePensionCalculator } from '../utils/usePensionCalculator'

export interface IncomeProjectionTextProps {}

const replacementPercentage = (projection: number, salary: number) =>
    Number(((projection / (Number(salary) / 12)) * 100).toFixed(0))

export const IncomeProjectionText: React.FC<IncomeProjectionTextProps> = () => {
    const {
        state: { projection, retirementAge, salary, statePension },
    } = usePensionCalculator()
    return (
        <>
            <Text py={'12px'} variant={'body-sm'}>
                Based on a number of assumptions this will give you a retirement
                income of €{numeral(projection + statePension).format('0,000')}{' '}
                per month from your {retirementAge}th birthday. This will
                replace{' '}
                {replacementPercentage(projection + statePension, salary)}% of
                your current yearly salary.{' '}
            </Text>
            <Box borderWidth='2px' borderRadius={'4px'} borderColor={'#4E4670'}>
                <Text fontWeight={'bold'} variant={'body-md'} p={'16px'}>
                    Warning: These figures are estimates only. They are not a
                    reliable guide to future performance of your investment.
                </Text>
            </Box>
        </>
    )
}
