import React, { useEffect } from 'react';

interface IAppointeddWidget {
  id: string;
}

const AppointeddWidget: React.FC<any> = (props: IAppointeddWidget) => {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src =
      'https://booking-tools-sdk.appointedd.com/appointedd-booking-tools-sdk-v1.js';
    document.body.appendChild(script1);

    const script2 = document.createElement('script');

    script2.lang = 'javascript';
    script2.type = 'text/javascript';

    script2.text = `Appointedd.renderWidget("appointeddWidgetContainer", {
    widgetId: '${props.id}' ,
    enableLanguageSelector: false,
  });`;

    setTimeout(function () {
      document.body.appendChild(script2);
    }, 300);
  }, [props.id]);
  return (
    <>
      <div
        id="appointeddWidgetContainer"
        style={{ padding: '32px', backgroundColor: '#FFFFFF' }}
      ></div>
    </>
  );
};

export { AppointeddWidget };
