import { Box, Flex, Image } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { useCalculatorMachine } from '../utils/context'

export interface HeadingProps {
    title: string
    subtitle: string
    description: string
    icon: string
}

export const Heading: React.FC<HeadingProps> = ({
    title,
    subtitle,
    description,
    icon,
}) => {
    const isDesktop = useDesktop()
    const { state } = useCalculatorMachine()
    return (
        <>
            <Flex align='start' justify='flex-start' wrap='nowrap'>
                <Image
                    src={icon}
                    boxSize={isDesktop ? '64px' : '48px'}
                    alt='calculator icon'
                    marginRight='12px'
                />
                <Box>
                    <Text
                        variant={isDesktop ? 'title-lg' : 'title-xs'}
                        mb='6px'
                    >
                        {title}
                    </Text>
                    <Text variant={isDesktop ? 'caption-xl' : 'caption-md'}>
                        {subtitle}
                    </Text>
                </Box>
            </Flex>
            {state.context.screenNo === 0 && description ? (
                <Text variant='body-md' py='16px' pl='24px' maxW='477px'>
                    {description}
                </Text>
            ) : (
                <Box mt='16px' />
            )}
        </>
    )
}
