import { useActor, useInterpret } from '@xstate/react'
import React, { createContext, useContext } from 'react'
import { InterpreterFrom } from 'xstate'
import { CalculatorMachine } from './machine'

export const CalculatorMachineContext = createContext({
    calculatorService: {} as InterpreterFrom<typeof CalculatorMachine>,
})

export const CalculatorMachineProvider = (props: any) => {
    const calculatorService = useInterpret(CalculatorMachine)
    return (
        <CalculatorMachineContext.Provider value={{ calculatorService }}>
            {props.children}
        </CalculatorMachineContext.Provider>
    )
}

export const useCalculatorMachine = () => {
    const globalServices = useContext(CalculatorMachineContext)
    const [state, send] = useActor(globalServices.calculatorService)
    return { state, send }
}
