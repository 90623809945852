import { CountingCardsWithPagination } from 'common/components/organisms/CountingCardsWithPagination/index'
import React, { FC } from 'react'

const CountingCardsWithPaginationWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <CountingCardsWithPagination {...processedProps} />
}

export { CountingCardsWithPaginationWrapper as CountingCardsWithPagination }

const getProps = (props: any) => {
    return {
        title: props.title,
        items:
            props?.mini_cards.map((item: any) => {
                return {
                    title: item.title,
                    text: item.subtitle,
                }
            }) || [],
    }
}
