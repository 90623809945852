export const investmentTool = {
    onSubmit: (data: any) => onSubmit(data),
    stepsTransformation: (steps: any, data?: any) => {
        return steps
    },
}

const onSubmit = (data: any) => {
    console.log('data on Submit')
}
