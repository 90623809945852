import React from 'react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { AccordionSection } from 'common/components/organisms/templateSections/AccordionSection'
import { coverRequirements } from './../utils/helperFunctions'
export interface CoverRequirementProps {}

export const CoverRequirement: React.FC<CoverRequirementProps> = () => {
    return (
        <Card mt={'16px'}>
            <AccordionSection {...coverRequirements} titleStyle={'title-xs'} />
        </Card>
    )
}
