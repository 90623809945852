import { pensionsTool } from './pensions'
import { investmentTool } from './investment'
import { incomeProtectionTool } from './incomeProtection'
import { termLifeTool } from './termLife'
import { mortgageProtectionTool } from './mortgageProtection'
import { onePlanProtectionTool } from './onePlan'
export const config: { [key: string]: any } = {
    Pensions: pensionsTool,
    Investment: investmentTool,
    IncomeProtection: incomeProtectionTool,
    TermLife: termLifeTool,
    MortgageProtection: mortgageProtectionTool,
    OnePlan: onePlanProtectionTool,
}
