import { AccordionProps } from '@irishlife/ilgroupdesignsystem.molecules.accordion'
import { Wrapper } from 'common/components/molecules/Wrapper'
import React from 'react'
import { AccordionSection } from 'common/components/organisms/templateSections/AccordionSection/AccordionSection'
import { Box, Grid } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'

interface AccordionInfoPanelProps extends AccordionProps {
    description: string
    subtitle: string
    paragraphTitle: string
}

export const AccordionInfoPanel: React.FC<AccordionInfoPanelProps> = (
    props
) => {
    const data = props

    return (
        <Wrapper>
            <Grid
                templateColumns={{ md: '4fr 1fr 7fr' }}
                alignContent='top'
                justifyItems='center'
            >
                <Box mb={{ base: 8, md: 0 }}>
                    <Text
                        mb={8}
                        color='#4DB7AB'
                        fontWeight={'bold'}
                        textTransform='uppercase'
                    >
                        {data.paragraphTitle}
                    </Text>
                    <Text
                        mb={8}
                        variant={'title-xl'}
                        color='#3A3160'
                        fontWeight={'medium'}
                    >
                        {data.subtitle}
                    </Text>
                    {data.description && (
                        <Text
                            variant={'subtitle-md'}
                            color='#766F90'
                            fontWeight={'normal'}
                        >
                            {data.description}
                        </Text>
                    )}
                </Box>
                <Box display={{ base: 'none', md: 'block' }}></Box>
                <Box h='-webkit-fill-available' w='100%'>
                    <AccordionSection {...props} />
                </Box>
            </Grid>
        </Wrapper>
    )
}
