import { Box, Image, Stack } from '@chakra-ui/react'
import { Button, ButtonProps } from '@irishlifedigitalhub/ds.atoms.button'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { Stepper as IrishStepper } from '@irishlifedigitalhub/ds.molecules.stepper'
import { navigate } from '@reach/router'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { useMemo, useState } from 'react'
import { Stepper } from '../../molecules/StepperWithArrows/index'

export type Card = {
    caption?: string
    title?: string
    subtitle?: string
    subtitle2?: string
    paragraph?: string
    buttonText?: string
    buttonProps?: ButtonProps
    secondaryButtonText?: string
    secondaryButtonProps?: ButtonProps
    children?: React.ReactNode
    imageSrc?: string
    callToAction?: any
}

export type Campaign3DSlideshowProps = {
    title: string
    cards: Card[]
}

export const Campaign3DSlideshow = (props: Campaign3DSlideshowProps) => {
    const { title, cards } = props

    const sliderImages = useMemo(() => {
        return cards.map((card: Card) => {
            return {
                src: card.imageSrc,
            }
        })
    }, [cards])

    const [images, setImages] = useState(sliderImages || [])
    const [index, setIndex] = useState(0)

    const card: Card = cards[index]

    const isDesktop = useDesktop()

    const slide = [
        {
            boxShadow: '0 1px 4px rgba(0,0,0, 0.4)',
            transform: ' translate3d(0%, 14%, 200px)',
        },
        {
            boxShadow:
                '0 6px 10px rgba(0,0,0, 0.3), 0 2px 2px rgba(0,0,0, 0.2)',
            transform: 'translate3d(0%, 7%, 100px)',
        },
        {
            boxShadow:
                '0 13px 26px rgba(0,0,0, 0.3), 0 12px 6px rgba(0,0,0, 0.2)',
            transform: 'translate3d(0%, 0%, 50px)',
        },
    ]

    React.useEffect(() => {
        if (index < 0) {
            setIndex(sliderImages.length - 1)
            return
        } else if (index > sliderImages.length - 1) {
            setIndex(0)
            return
        }
        shiftArrayToLast(index)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index])

    function shiftArrayToLast(index: number) {
        if (index >= 0 && index < sliderImages.length) {
            const tempArray = [...sliderImages]
            const element = tempArray.splice(index, 1)[0]

            tempArray.unshift(element)
            setImages(tempArray)
        }
    }

    return (
        <Stack data-testid='CampaignFeature' w='full'>
            <Stack
                spacing={0}
                direction={['column', 'column', 'row-reverse']}
                maxW='6xl'
                w='full'
                mx='auto'
                h={['auto', 'auto', '605px']}
            >
                <Box
                    w={['full', 'full', '60%']}
                    mb={[0, 0, -5]}
                    pt={[0, 0, 10]}
                    height={isDesktop ? '450px' : ''}
                >
                    <Stack
                        direction={['column', 'column', 'row-reverse']}
                        height={isDesktop ? '470px' : ''}
                        width='100%'
                    >
                        {isDesktop ? (
                            images && (
                                <Box height='100%' width='inherit'>
                                    <Box
                                        style={{
                                            position: 'relative',
                                            width: '90%',
                                            height: '100%',
                                            marginLeft: '10px',
                                            perspective: '1400px',
                                            transformStyle: 'preserve-3d',
                                        }}
                                    >
                                        {images.map(
                                            (image: any, index: number) => (
                                                <Image
                                                    key={index}
                                                    alt='Campaign Feature Image'
                                                    src={`${image.src}?auto=format&w=600`}
                                                    maxW={[
                                                        'full',
                                                        'full',
                                                        '500px',
                                                    ]}
                                                    w='full'
                                                    height={[64, 64, 'full']}
                                                    objectFit='cover'
                                                    style={{
                                                        position: 'absolute',
                                                        transition:
                                                            'transform 400ms ease',
                                                    }}
                                                    css={
                                                        slide[
                                                            index > 2
                                                                ? 2
                                                                : index
                                                        ]
                                                    }
                                                />
                                            )
                                        )}
                                    </Box>
                                </Box>
                            )
                        ) : (
                            <Image
                                alt='Campaign Feature Image'
                                src={`${sliderImages[index].src}?auto=format&w=600`}
                                srcSet={`
                                ${sliderImages[index].src}?auto=format&w=150 150w,
                                ${sliderImages[index].src}?auto=format&w=300 300w,
                                ${sliderImages[index].src}?auto=format&w=600 600w,
                                ${sliderImages[index].src}?auto=format&w=1200 1200w,
                                ${sliderImages[index].src}?auto=format&w=2400 2400w,
                                `}
                                sizes='(max-width: 767px) 100vw, 50vw'
                                maxW={['full', 'full', '500px']}
                                w='full'
                                height={[64, 64, 'full']}
                                objectFit='cover'
                            />
                        )}
                        <Box
                            height='100%'
                            {...(isDesktop
                                ? {}
                                : {
                                      display: 'flex',
                                      justifyContent: 'center',
                                  })}
                        >
                            <div
                                style={{
                                    marginTop: isDesktop ? '80px' : '24px',
                                }}
                            >
                                {isDesktop ? (
                                    <Stepper
                                        steps={images.length}
                                        leftIndicator
                                        vertically={isDesktop}
                                        onChange={(number) =>
                                            setIndex(number - 1)
                                        }
                                        defaultStep={index + 1}
                                    />
                                ) : (
                                    <IrishStepper
                                        steps={images.length}
                                        onChange={(number) =>
                                            setIndex(number - 1)
                                        }
                                        defaultStep={index + 1}
                                    />
                                )}
                            </div>
                        </Box>
                    </Stack>
                </Box>
                <Stack spacing={0} w={['full', 'full', '40%']} height={'auto'}>
                    <Stack
                        maxW={['full', 'full', '456px']}
                        w='full'
                        spacing={8}
                        px={4}
                        pb={[12, 12, 0]}
                        margin={'auto 0'}
                    >
                        <Text
                            pt={[6, 6, 0]}
                            display={title ? 'block' : 'none'}
                            variant='caption-lg'
                            color='teal.400'
                        >
                            {title}
                        </Text>
                        <Text
                            display={card?.title ? 'block' : 'none'}
                            variant='title-xl'
                        >
                            {card?.title}
                        </Text>
                        <Box>
                            <Text
                                display={card?.subtitle ? 'block' : 'none'}
                                variant='title-sm'
                                color='monochrome.700'
                            >
                                {card?.subtitle}
                            </Text>
                            <Text
                                display={card?.subtitle2 ? 'block' : 'none'}
                                fontWeight={['medium', 'medium', 'semibold']}
                                color='monochrome.700'
                                mt={2}
                            >
                                {card.subtitle2}
                            </Text>
                        </Box>
                        <Text
                            color={'monochrome.800'}
                            display={card?.paragraph ? 'block' : 'none'}
                        >
                            {card?.paragraph}
                        </Text>
                        <Box display={card?.children ? 'block' : 'none'}>
                            {card?.children}
                        </Box>
                        <Box
                            display={
                                card?.callToAction?.label ? 'block' : 'none'
                            }
                        >
                            <Button
                                {...card?.buttonProps}
                                onClick={() => {
                                    navigate(card?.callToAction?.url)
                                }}
                            >
                                {card?.callToAction?.label}
                            </Button>
                        </Box>
                        <Box
                            display={
                                card?.callToAction?.secondaryButtonText
                                    ? 'block'
                                    : 'none'
                            }
                        >
                            <Button
                                {...card?.secondaryButtonProps}
                                variant='secondary'
                            >
                                {card?.secondaryButtonText}
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}
