import {
    Calculator as CalculatorOrganism,
    CalculatorProps as CalculatorOrganismProps,
} from 'common/components/organisms/Calculator'
import React from 'react'

export interface CalculatorProps extends CalculatorOrganismProps {}

export const Calculator: React.FC<CalculatorProps> = (props) => {
    return <CalculatorOrganism {...props} />
}

export default Calculator
