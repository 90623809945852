import { Box, Image, VStack } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import React from 'react'
interface MediaCardProps {
    title?: string
    image?: string
}

export const MediaCard: React.FC<MediaCardProps> = ({ title, image }) => {
    return (
        <Box>
            <VStack alignItems={'center'}>
                <Text
                    align={'center'}
                    variant={'title-xl'}
                    fontWeight={'medium'}
                    color={'#4E4670'}
                    py={1}
                    w={['auto', 'auto', '65%']}
                >
                    {title}
                </Text>
                <Box style={{ marginTop: '64px' }} py={1}>
                    <Image
                        src={`${image}?auto=format&w=600`}
                        srcSet={`
                         ${image}?auto=format&w=150 150w,
                         ${image}?auto=format&w=300 300w,
                         ${image}?auto=format&w=600 600w,
                         ${image}?auto=format&w=1200 1200w,
                         ${image}?auto=format&w=2400 2400w,
                         `}
                        sizes='100vw'
                        alt='Sample Image'
                        height={'100%'}
                    />
                </Box>
            </VStack>
        </Box>
    )
}
