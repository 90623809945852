import { Box } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { PhoneIcon } from '@irishlife/ilgroupdesignsystem.icons'
import React from 'react'

export interface GetStartedProps {
    title: string
    subtitle: string
    phoneNumber: string
    bodyText?: string
}

export const GetStarted: React.FC<GetStartedProps> = ({ ...props }) => {
    const { title, subtitle, phoneNumber, bodyText } = props
    const phone = `tel: +353${phoneNumber}`
    return (
        <Box pt={'12px'} mb={'16px'}>
            <Text variant={'title-xs'}>{title}</Text>
            <Box mt={'16px'}>
                <Text mb={'16px'} variant={'body-md'}>
                    {subtitle}
                </Text>
                <a href={phone}>
                    <Button
                        leftIcon={
                            <PhoneIcon fontSize={'28px'} color={'#FFFFF'} />
                        }
                        variant='primary'
                        size='md'
                    >
                        {`Call us ${phoneNumber}`}
                    </Button>
                </a>

                {bodyText && (
                    <Text mt={'24px'} variant={'body-md'}>
                        {bodyText}
                    </Text>
                )}
            </Box>
        </Box>
    )
}
