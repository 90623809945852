import { Box } from '@chakra-ui/react'
import { HotStepper } from '@irishlifedigitalhub/ds.molecules.hot-stepper'
import React, { useState, useEffect } from 'react'
import { useCalculatorMachine } from '../utils/context'
import { ProgressBarStepperStepsType } from '../utils/interfaces'
import { stepperSteps } from '../utils/progressIndicatorSteps'

export interface ProgressIndicatorProps {
    calculatorType: keyof ProgressBarStepperStepsType
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
    calculatorType,
}) => {
    const [calcType, setCalcType] = useState(calculatorType)
    const { state } = useCalculatorMachine()
    let currentStep = 1
    useEffect(() => {
        if (
            calculatorType === 'OnePlan' &&
            state.context.transformedQuestions.length === 4
        ) {
            setCalcType('OnePlanWithPartner')
        }
    }, [calculatorType, state.context.transformedQuestions.length])

    switch (true) {
        case (state.context.quoteData as any)?.projection > 0 ||
            state.matches('Quote Screen') ||
            state.matches('Refetching Quote Details'):
            currentStep = stepperSteps[calcType].length
            break
        default:
            currentStep = state.context.screenNo + 1
    }
    return (
        <Box id='ProgressIndicator' mb='16px' pb='18px'>
            <HotStepper
                currentStep={currentStep}
                steps={stepperSteps[calcType]}
            />
        </Box>
    )
}

export default ProgressIndicator
