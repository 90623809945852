import { Box } from '@chakra-ui/react'
import React from 'react'
import { AssumptionsCard } from './components/AssumptionsCard'
import { BenefitsCard } from '../common/BenefitsCard'
import { NextStepsCard } from './components/NextStepsCard'
import { PensionCalculatorOutput } from './components/PensionCalculatorOutput'
import { WarningAlerts } from '../common/WarningAlerts'
export interface PensionCalcQuoteProps {
    props?: any
}
export const PensionCalcQuote: React.FC<PensionCalcQuoteProps> = () => {
    const benefits = [
        `Our advisors are on hand to help you and offer guidance.`,
        `We support our customers up until and after retirement.`,
        `Customers who had a financial review with us gave it a score of 94.4%.`,
    ]
    const benefitsTitle = ` Why choose Irish Life?`
    return (
        <Box>
            <PensionCalculatorOutput />
            <NextStepsCard />
            <BenefitsCard benefits={benefits} title={benefitsTitle} />
            <AssumptionsCard />
            <WarningAlerts />
        </Box>
    )
}
