import { Grid, GridSize } from '@material-ui/core'
import { FeatureListImage } from 'common/components/organisms/FeatureList/FeatureListImage'
import React from 'react'
import { Bullets, BulletsProps } from './Bullets'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BulletsWithImageProps extends BulletsProps {
    imagePlacement: 'left' | 'right'
}

export const BulletsWithImage: React.FC<BulletsWithImageProps> = props => {
    const data = getBulletsWithImageData(props)
    const layout = data.imagePlacement === 'left' ? [5, 6] : [6, 5]
    const left =
        data.imagePlacement === 'left' ? (
            <FeatureListImage {...data} variant={data.imagePlacement} />
        ) : (
            <Bullets {...data} />
        )
    const right =
        data.imagePlacement === 'left' ? (
            <Bullets {...data} />
        ) : (
            <FeatureListImage {...data} variant={data.imagePlacement} />
        )
    return (
        <Grid data-testid='BulletsWithImage' container>
            <Grid item xs={12} md={layout[0] as GridSize}>
                {left}
            </Grid>
            <Grid item md={1} />
            <Grid item xs={12} md={layout[1] as GridSize}>
                {right}
            </Grid>
        </Grid>
    )
}

export const getBulletsWithImageData = (data: any): BulletsWithImageProps => {
    return {
        ...data,
        imagePlacement: data.image_placement
    }
}
