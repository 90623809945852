import { Box } from '@chakra-ui/react'
import React from 'react'
import { FooterCommonNotes, FooterContactNotes } from './FooterCommon'
export const PensionFooterNotes: React.FC<any> = () => {
    return (
        <>
            <Box pt={'16px'}>
                <ul>
                    <FooterCommonNotes />
                </ul>
            </Box>
            <FooterContactNotes />
        </>
    )
}
