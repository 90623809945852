import { Box } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import React from 'react'
import { assumptions } from '../config'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { INVESTMENT_RATE } from './InvestmentCalculatorOutput/utils/getCalculatorReturns'

export interface WarningsProps {}

export const Warnings: React.FC<WarningsProps> = () => {
    return (
        <>
            <Card mt={'16px'}>
                <Box>
                    <Text
                        color={'var(--Monochrome-800, #615A80)'}
                        variant={'title-xs'}
                    >
                        Two easy ways to start
                    </Text>
                    <Text
                        mt={'12px'}
                        mb={'36px'}
                        variant={'body-md'}
                        color={'var(--Monochrome-800, #615A80)'}
                    >
                        ` While this calculator shows you how much{' '}
                        {INVESTMENT_RATE}% interest might look, We offer
                        financial advice or you can get started investing online
                        using our app Smart Invest.`
                    </Text>
                    <Box
                        d='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <a href='/get-advice'>
                            <Button
                                mb={'24px'}
                                variant='primary'
                                size='lg'
                                mt='12px'
                            >
                                Book an appointment
                            </Button>
                        </a>

                        <a href='/investments/smart-invest/'>
                            <Button variant='secondary' size='lg' mt='12px'>
                                Get started online{' '}
                            </Button>
                        </a>
                    </Box>
                </Box>
            </Card>
            <Card mt={'16px'}>
                <Box pb={'12px'}>
                    <Text
                        mb={'12px'}
                        color={'var(--Monochrome-800, #615A80)'}
                        variant={'title-xs'}
                    >
                        Assumptions and other key information:
                    </Text>
                    <ul>
                        {assumptions.map((item) => (
                            <li key={item}>
                                <Text variant={'body-sm'}>{item}</Text>
                            </li>
                        ))}
                    </ul>
                </Box>
            </Card>
        </>
    )
}
