import { ImageWithParagraph } from 'common/components/organisms/ImageWithParagraph/index'
import React from 'react'

const ImageWithParagraphWrapper: React.FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <ImageWithParagraph {...processedProps} />
}
export { ImageWithParagraphWrapper as ImageWithParagraph }
const getProps = (data: any) => ({
    image: data.image,
    title: data.text,
    callToAction: {
        analyticsId: data.button_c9ad60e__analytics_id || '',
        label: data.card_cta_0396ba7__cta_label,
        url: data.card_cta_0396ba7__cta_url,
        endIcon: data.card_cta_0396ba7__cta_icon,
        variant: data.card_cta_0396ba7__cta_variant,
    },
})
