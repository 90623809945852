import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'

interface CardComponentProps {
    text: string
    title: string
    counter: number
}

const Card: React.FC<CardComponentProps> = ({ text, title, counter }) => {
    return (
        <Box
            display='flex'
            flexDirection='column'
            borderRadius='16px'
            background='white'
            boxShadow='4px 0px 16px rgba(82, 97, 172, 0.15)'
            p='24px'
            height='full'
        >
            <Flex
                justifyContent='center'
                alignItems='center'
                width='40px'
                height='40px'
                borderRadius='full'
                background='#F1F3FD'
                mb='24px'
            >
                <Text color='#5261AC' fontSize='26px' fontWeight='700'>
                    {counter}
                </Text>
            </Flex>
            <Text color='#0C1E68' fontSize='20px' fontWeight='600' mb='13px'>
                {title}
            </Text>
            <Text color='#4E4670' fontSize='16px' fontWeight='500'>
                {text}
            </Text>
        </Box>
    )
}

export default Card
