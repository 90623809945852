import { Box } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import React from 'react'
import { FooterCommonNotes, FooterContactNotes } from './FooterCommon'
export const IncomeProtectionFooterNotes: React.FC<any> = () => {
    return (
        <>
            <Box pt={'16px'}>
                <ul>
                    <FooterCommonNotes />
                    <li>
                        <Text
                            fontSize={'16px'}
                            color={'var(--Monochrome-800, #615A80)'}
                            fontWeight={'normal'}
                        >
                            {`*In general, the most cover you can have at any one
                            time (including that provided under other income
                            protection plans and continuing income from your job
                            or pension) is: €250,000 a year; or 75% of your
                            total yearly earnings; less any state benefits for
                            disability (except benefits for children) and other
                            forms of income you may have. Provides you with a
                            replacement income if you cannot work as a result of
                            an illness or injury after a certain period of time.
                            It does not cover you if you become unemployed.`}
                        </Text>
                    </li>
                </ul>
            </Box>

            <FooterContactNotes />
        </>
    )
}
