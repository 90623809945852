import { getDataPoints } from './get-data-points'
import { getInvestmentValue } from './get-investment-value'

export const INVESTMENT_RATE = 3.55

const CASH_RATE = 0.0000001
export const getCalculatorReturns = (state: any) => {
    const investmentRate = INVESTMENT_RATE / 100
    const returnOnCash = getInvestmentValue(
        state.lumpSum,
        state.regular,
        CASH_RATE,
        state.term
    )
    const returnOnInvestment = getInvestmentValue(
        state.lumpSum,
        state.regular,
        investmentRate,
        state.term
    )
    const cashDataPoints = getDataPoints(
        state.lumpSum,
        state.regular,
        CASH_RATE,
        state.term
    )
    const investmentDataPoints = getDataPoints(
        state.lumpSum,
        state.regular,
        investmentRate,
        state.term
    )
    return {
        returnOnCash,
        returnOnInvestment,
        cashDataPoints,
        investmentDataPoints,
    }
}
