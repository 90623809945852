/*

The formula for calculating the future value of a compound investment with additional regular payments is:

FV = PV * (1 + r/n)^(nt) + PMT * (((1 + r/n)^(nt) - 1) / (r/n))

where:

FV is the future value of the investment
PV is the present value or initial principal of the investment
r is the annual interest rate
n is the number of compounding periods per year
t is the number of years the investment is held
PMT is the amount of the additional regular payments
This formula assumes that the additional regular payments are made at the end of each compounding period, and that the interest rate remains constant over the life of the investment.

For example, if you have an initial investment of $1000, an annual interest rate of 2.5%, and you make additional regular payments of $100 at the end of each year for a total of 5 years, the future value of the investment would be:

FV = 1000 * (1 + 0.05/1)^(15) + 100 * (((1 + 0.05/1)^(15) - 1) / (0.05/1))
= 1000 * (1.05)^5 + 100 * ((1.05^5 - 1) / 0.05)
= $1828.85

This means that the investment will be worth $1628.89 at the end of the 5 years, including the additional regular payments and the compound interest earned.
*/

export const getInvestmentValue = (
    principal: number,
    regular: number,
    rate: number,
    term: number,
    n: number = 1
): number => {
    return (
        principal * (1 + rate / n) ** (n * term) +
        regular * 12 * ((((1 + rate) / n) ** (n * term) - 1) / (rate / n))
    )
}
