import { Box, Container, SimpleGrid } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { navigate } from '@reach/router'
import { theme } from 'common/components/design-system/theme'
import { CardCtaProps } from 'common/components/molecules/CardCta'
import React from 'react'

export interface ProofPointsProps {
    heading?: string
    cards: {
        title: string
        text: string
        icon: string
        callToAction: CardCtaProps
    }[]
    variant?: 'light' | 'dark'
}

export const ProofPoints: React.FC<ProofPointsProps> = ({
    cards,
    heading,
    variant,
}) => {
    const isDark = variant === 'dark' ? true : false
    const background = isDark ? theme.palette.VIBRANT_700 : 'transparent'

    const lightStyles = {
        color: theme.palette.BRAND_900,
    }

    const darkStyles = {
        color: '#fff',
        ' p': {
            color: '#fff',
        },
    }

    return (
        <Container data-testid='ProofPoints' maxW='6xl' px={0}>
            <Box backgroundColor={background} rounded='16px' p={6} py={14}>
                {heading && (
                    <Box
                        display='flex'
                        justifyContent='center'
                        mb={14}
                        w='full'
                    >
                        <Text
                            variant='title-lg'
                            sx={isDark ? darkStyles : lightStyles}
                        >
                            {heading}
                        </Text>
                    </Box>
                )}
                <SimpleGrid
                    columns={[1, 1, 1, 3]}
                    spacing={12}
                    px={[0, 24, 24]}
                >
                    {cards?.map((card) => (
                        <Box
                            key={card.title}
                            display='flex'
                            flexDirection='column'
                            justifyContent='space-between'
                        >
                            {card.icon && (
                                <img
                                    src={card.icon}
                                    width={40}
                                    height={40}
                                    alt='An icon'
                                />
                            )}

                            <Text
                                variant='title-sm'
                                sx={isDark ? darkStyles : lightStyles}
                                fontWeight='medium'
                                my={2}
                            >
                                {card.title}
                            </Text>
                            {card.text && (
                                <Text
                                    variant='body-md'
                                    sx={isDark ? darkStyles : lightStyles}
                                    fontWeight='semibold'
                                >
                                    {card.text}
                                </Text>
                            )}
                            {card.callToAction &&
                                !Array.isArray(card.callToAction) &&
                                card.callToAction.label && (
                                    <Box width='min-content'>
                                        <Button
                                            mt={6}
                                            px={6}
                                            size='sm'
                                            variant={
                                                isDark ? 'outline' : 'vibrant'
                                            }
                                            onClick={() => {
                                                if (card.callToAction.url) {
                                                    navigate(
                                                        card.callToAction.url
                                                    )
                                                }
                                            }}
                                        >
                                            {card.callToAction.label}
                                        </Button>
                                    </Box>
                                )}
                        </Box>
                    ))}
                </SimpleGrid>
            </Box>
        </Container>
    )
}
