import { Box, HStack, Image, Stack } from '@chakra-ui/react'
import { Button, ButtonProps } from '@irishlifedigitalhub/ds.atoms.button'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { Link } from 'common/components/atoms/Link'
import { Wrapper } from 'common/components/molecules/Wrapper'
import React, { useState } from 'react'
import { Stepper } from '../../molecules/StepperWithArrows/index'

export type Card = {
    caption?: string
    title?: string
    jobTitle?: string
    department?: string
    subtitle?: string
    subtitle2?: string
    paragraph?: string
    buttonText?: string
    callToAction?: any
    secondaryButtonText?: string
    secondaryButtonProps?: ButtonProps
    imageLeft?: boolean
    children?: React.ReactNode
    imageSrc?: string
}

export type MultiCampaignFeatureProps = {
    title: string
    cards: Card[]
}

export const MultiCampaignFeature = (props: MultiCampaignFeatureProps) => {
    const { title, cards } = props
    const [index, setIndex] = useState(0)

    const card: Card = cards[index]

    return (
        <Wrapper>
            <Stack
                data-testid='CampaignFeature'
                w='full'
                pt={['30px', '30px', '20px']}
            >
                <Container imageLeft={card?.imageLeft}>
                    <Box w={['full', 'full', '50%']} mb={[0, 0, -5]}>
                        {card?.imageSrc && (
                            <HStack height='100%' justifyContent={'center'}>
                                <Image
                                    alt='Campaign Feature Image'
                                    src={`${card?.imageSrc}?auto=format&w=600`}
                                    srcSet={`
                                        ${card?.imageSrc}?auto=format&w=150 150w,
                                        ${card?.imageSrc}?auto=format&w=300 300w,
                                        ${card?.imageSrc}?auto=format&w=600 600w,
                                        ${card?.imageSrc}?auto=format&w=1200 1200w,
                                        ${card?.imageSrc}?auto=format&w=2400 2400w,
                                    `}
                                    sizes='(max-width: 767px) 100vw, 50vw'
                                    maxW={['full', 'full', '500px']}
                                    w='full'
                                    height={[64, 64, '400px']}
                                    sx={{ objectFit: 'cover' }}
                                />
                            </HStack>
                        )}
                    </Box>
                    <Stack spacing={0} w={['full', 'full', '50%']}>
                        <Stack
                            maxW={['full', 'full', '600px']}
                            w='full'
                            spacing={8}
                            pl={{ md: card.imageLeft ? '15%' : 0 }}
                            pr={{ md: !card.imageLeft ? '15%' : 0 }}
                            pb={[12, 12, 4]}
                        >
                            {card?.imageLeft && (
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    justifyContent={{
                                        base: 'center',
                                        md: 'flex-end',
                                    }}
                                    pt={[6, 6, 0]}
                                >
                                    <Stepper
                                        steps={cards.length}
                                        leftIndicator
                                        defaultStep={1}
                                        onChange={(number) =>
                                            setIndex(number - 1)
                                        }
                                    />
                                </Box>
                            )}
                            <Text
                                pt={card?.imageLeft ? '' : [6, 6, 0]}
                                display={title ? 'block' : 'none'}
                                variant='caption-lg'
                                color='teal.400'
                            >
                                {title}
                            </Text>
                            <Box>
                                <Text
                                    display={card?.title ? 'block' : 'none'}
                                    variant='title-xl'
                                    width={'70%'}
                                >
                                    {card.title}
                                </Text>
                                <Text
                                    display={card?.subtitle2 ? 'block' : 'none'}
                                    fontWeight={[
                                        'medium',
                                        'medium',
                                        'semibold',
                                    ]}
                                    color='monochrome.700'
                                    mt={2}
                                >
                                    {card.subtitle2}
                                </Text>
                            </Box>
                            <Text
                                display={card?.subtitle ? 'block' : 'none'}
                                variant='title-sm'
                                color='monochrome.700'
                            >
                                {card?.subtitle}
                            </Text>
                            <Text
                                color={'monochrome.800'}
                                display={card?.paragraph ? 'block' : 'none'}
                            >
                                {card?.paragraph}
                            </Text>
                            <Box display={card?.children ? 'block' : 'none'}>
                                {card?.children}
                            </Box>
                            <Box
                                display={
                                    card?.callToAction?.label ? 'block' : 'none'
                                }
                            >
                                <Link path={card?.callToAction?.url}>
                                    <Button {...card?.callToAction}>
                                        {card?.callToAction?.label}
                                    </Button>
                                </Link>
                            </Box>
                            <Box
                                display={
                                    card?.secondaryButtonText ? 'block' : 'none'
                                }
                            >
                                <Button
                                    {...card?.secondaryButtonProps}
                                    variant='secondary'
                                >
                                    {card?.secondaryButtonText}
                                </Button>
                            </Box>
                            {!card?.imageLeft && (
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    justifyContent={{
                                        base: 'center',
                                        md: 'flex-start',
                                    }}
                                >
                                    <Stepper
                                        steps={cards.length}
                                        leftIndicator
                                        defaultStep={1}
                                        onChange={(number) => {
                                            setIndex(number - 1)
                                        }}
                                    />
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
        </Wrapper>
    )
}

function Container(props: Record<string, any>) {
    return (
        <Stack
            spacing={0}
            direction={[
                'column',
                'column',
                props?.imageLeft ? 'row' : 'row-reverse',
            ]}
            maxW='6xl'
            w='full'
            mx='auto'
            {...props}
        />
    )
}
