import { MultiCardGalleryWithStepper } from 'common/components/organisms/MultiCardGalleryWithStepper/index'
import React, { FC } from 'react'

const MultiCardGalleryWithStepperWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <MultiCardGalleryWithStepper {...processedProps} />
}
export { MultiCardGalleryWithStepperWrapper as MultiCardGalleryWithStepper }

const getProps = (data: any) => {
    return {
        title: data.title,
        cards: data.career_card_with_pagination.map((item: any) => {
            return {
                icon: item.icon,
                text: item.text,
                title: item.title,
                image: item.card_cta_0396ba7__cta_icon,
            }
        }),
    }
}
