import React, { FC } from 'react'
import { AccordionInfoPanel } from 'common/components/organisms/AccordionInfoPanel/AccordionInfoPanel'

export const AccordionInfoPanelWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <AccordionInfoPanel {...processedProps} />
}
export { AccordionInfoPanelWrapper as AccordionInfoPanel }

export const getProps = (data: any) => {
    return {
        paragraphTitle: data.title,
        description: data.description,
        subtitle: data.subtitle,
        ...data,
    }
}
