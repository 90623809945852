import { Box, useTheme } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { Bullet, BulletProps } from './Bullet'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BulletsProps {
    bullets: BulletProps[]
    title: string
    subtitle?: string
}

export const Bullets: React.FC<BulletsProps> = ({
    bullets = [],
    title,
    subtitle
}) => {
    const desktop = useDesktop()
    const theme = useTheme()

    return (
        <>
            <Spacer variant='sm' />
            <Box>
                <Text
                    box={{
                        mb: subtitle ? 2 : 10
                    }}
                    label={title}
                    variant={desktop ? 'h4' : 'h2'}
                    component='h1'
                />
                <Text
                    box={{ mb: 10 }}
                    color='textSecondary'
                    html
                    label={subtitle || ''}
                    variant='subtitle1'
                    component='p'
                />
                <Box
                    display='flex'
                    flexWrap='wrap'
                    style={{
                        rowGap: theme.spacing(3),
                        columnGap: theme.spacing(3)
                    }}
                >
                    {bullets.map(bullet => (
                        <Bullet key={bullet.title} {...bullet} />
                    ))}
                </Box>
            </Box>
        </>
    )
}
