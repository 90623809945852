import { Box } from '@chakra-ui/react'
import { Container } from 'common/components/organisms/Calculator/components/container'
import { CalculatorMachineProvider } from 'common/components/organisms/Calculator/utils/context'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CalculatorProps {
    steps: any
}

export const Calculator: React.FC<CalculatorProps> = (props) => {
    return (
        <CalculatorMachineProvider>
            <Box id='Calculator'>
                <Container props={props} />
            </Box>
        </CalculatorMachineProvider>
    )
}
