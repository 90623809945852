import { Box, Grid, Text } from '@chakra-ui/react'
import { ArrowRightCircleOutlineIcon } from '@irishlife/ilgroupdesignsystem.icons'

import React from 'react'

/* eslint-disable-next-line */
export interface CostumPopLinkProps {
    title: string
    icon: JSX.Element
    handleClick?: () => void
}

const CustomPoplink = ({ title, icon, handleClick }: CostumPopLinkProps) => {
    return (
        <Grid
            templateColumns='1fr 10fr'
            alignItems='center'
            py={4}
            cursor='pointer'
            onClick={handleClick}
        >
            <Box justifyContent='center' alignItems='center'>
                {icon}
            </Box>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
            >
                <Text fontSize='18px' fontWeight={600} color='#5261AC'>
                    {title}
                </Text>

                <Box onClick={handleClick}>
                    <ArrowRightCircleOutlineIcon
                        fontSize={24}
                        color='#5261AC'
                    />
                </Box>
            </Box>
        </Grid>
    )
}

export default CustomPoplink
