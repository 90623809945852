import { Box } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React from 'react'

export interface ValidityCardProps {}

export const ValidityCard: React.FC<ValidityCardProps> = () => {
    return (
        <>
            <Box pt={'12px'} mb={'16px'}>
                <Card px={'36px'}>
                    <Text variant={'title-xs'}>Validity</Text>
                    <Box mt={'16px'}>
                        <Text>
                            This quote is valid for 7 days or until your next
                            birthday, whichever comes sooner.
                        </Text>
                        <Text mt={'16px'}>
                            This product is based on our{' '}
                            <a
                                href={'/life-insurance/income-protection/'}
                                style={{
                                    color: '#3681D9',
                                }}
                            >
                                Personal Income Insurance product.
                            </a>
                        </Text>
                    </Box>
                </Card>
            </Box>
        </>
    )
}
