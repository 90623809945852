import { TrapeziumBanner } from 'common/components/organisms/TrapeziumBanner/index'
import React, { FC } from 'react'

const TrapeziumBannerWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <TrapeziumBanner {...processedProps} />
}
export { TrapeziumBannerWrapper as TrapeziumBanner }
const getProps = (props: any) => {
    return {
        image: props.image,
        title: props.title,
        subtitle: props.subtitle,
        callToAction: {
            label: props.button_c9ad60e__text,
            url: props.button_c9ad60e__url,
        },
    }
}
