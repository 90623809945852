import React, { useState } from 'react'
import { Box } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { QuoteCardHeaders } from './components/QuoteCardheaders'
import { QuoteCard } from './components/QuoteCard'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { getOnePlanColumns } from './utils/helperFunctions'
export interface OnePlanProtectionOutputProps {}

export const OnePlanProtectionOutput: React.FC<OnePlanProtectionOutputProps> =
    () => {
        const { state } = useCalculatorMachine()
        type ColumnState = {
            isSelected: object
        }[]
        const activeStyles = {
            borderBottom: '4px solid #6977DF',
            boxShadow: '0px 0px 20px rgba(82, 97, 172, 0.24)',
            borderRadius: '12px',
            background: '#FFFFFF',
        }
        const columns = getOnePlanColumns(state).columns
        const [column, setColumn] = useState(columns[0])
        const initialColumnState: ColumnState = columns?.map(
            (column: any, index: number) => {
                if (index === 0) {
                    return {
                        isSelected: activeStyles,
                    }
                }
                return { isSelected: {} }
            }
        )
        const [columnClass, setcolumnClass] =
            React.useState<ColumnState>(initialColumnState)
        const handleHeaderClick = (column: any, index: number) => {
            setColumn(column)
            const columnState: ColumnState = columnClass.map(
                (item, itemIndex: number) => {
                    return itemIndex === index
                        ? { ...item, isSelected: activeStyles }
                        : { ...item, isSelected: {} }
                }
            )
            setcolumnClass(columnState)
        }
        return (
            <Box>
                <Text variant={'title-sm'} my={'16px'}>
                    Your quote
                </Text>
                <QuoteCardHeaders
                    columnClass={columnClass}
                    columns={columns}
                    handleHeaderClick={handleHeaderClick}
                />
                <QuoteCard coverDetails={column} />
            </Box>
        )
    }
