import { PensionCalcQuote } from '../quoteComponents/PensionCalculatorQuote'
import { InvestmentCalcQuote } from '../quoteComponents/InvestmentCalculatorQuote'
import { IncomeProtectionQuote } from '../quoteComponents/IncomeProtectionQuote'
import { TermLifeQuote } from './TermLifeQuote'
import { MortgageProtectionQuote } from './MortgageProtectionQuote'
import { OnePlanProtectionQuote } from './OnePlanProtectionQuote'
export const QuoteComponentMapper: { [key: string]: any } = {
    Pensions: PensionCalcQuote,
    Investment: InvestmentCalcQuote,
    IncomeProtection: IncomeProtectionQuote,
    TermLife: TermLifeQuote,
    MortgageProtection: MortgageProtectionQuote,
    OnePlan: OnePlanProtectionQuote,
}
