import React from 'react'
import { Alerts, AlertsProps } from 'common/components/organisms/Alerts'



// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface WarningsProps extends AlertsProps {}

export const Warnings: React.FC<WarningsProps> = props => {
    const data = getWarningsData(props)
    return (
        <Alerts {...data} />
    )
}

export const getWarningsData = (data: any): WarningsProps => {
    return {
        alerts: data.alerts__alerts.map((alert: any) => {
            return alert.text
        })
    }
}
