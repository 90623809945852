import { APIrequest } from './APIrequests'
import qs from 'qs'
import { API_URL } from 'core'

// const API_URL = 'https://apps.irishlife.ie'
const API: string = `${API_URL}/myonlineservices/servlet/LifeCoverQuote`
export const termLifeTool = {
    onSubmit: (data: any) => onSubmit(data),
    stepsTransformation: (steps: any, data?: any) => {
        return steps
    },
}

export const onSubmit = (data: any): Promise<any> => {
    return APIrequest(API, getApiConstants('lifeTermSum', 19, data)).then(
        (response: any) => {
            if (response !== undefined) {
                return response
            }
        }
    )
}

export const getApiConstants: any = (
    quickQuoteId: string,
    productId: number,
    data: any
) => {
    return qs.stringify({
        quickQuoteId: quickQuoteId,
        productId: productId,
        lifeCoverAmt: data.coverNeeded,
        conversion: 'FALSE',
        coverTypeCd: 'L',
        indexation: 'FALSE',
        frequencyCd: 'M',
        dateOfBirth1Day: 18,
        dateOfBirth1Month: 9,
        dateOfBirth1Year: new Date().getFullYear() - +data.age, ///need to logic here
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        term: +data.maxAge - +data.age,
        age1: data.age,
        smokerCd1: data.isSmoker === 'Y' ? 'Y' : 'N',
    })
}
