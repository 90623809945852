import { Link } from '@reach/router'
import React, { PropsWithChildren } from 'react'
export interface UniversalLinkProps extends PropsWithChildren<{}> {
    to: string
}

export const UniversalLink: React.FC<UniversalLinkProps> = ({
    to,
    children,
    ...props
}) => {
    // Check if the URL is external
    const isExternal = (url: string) => {
        return /^(https?:|mailto:|tel:)/.test(url)
    }

    if (isExternal(to)) {
        return (
            <a href={to} {...props}>
                {children}
            </a>
        )
    } else {
        return (
            <Link to={to} {...props}>
                {children}
            </Link>
        )
    }
}
